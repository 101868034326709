import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, } from "@mui/material";
import { logEvent, uploadEvent } from "../../networking";
import { useLocalStorage } from "@uidotdev/usehooks";


export default function LoggerDialog({pageName = "unknownPage", ...props}) {
    const {
        open, 
        onOpen = () => undefined,
        onClose = () => undefined, 
        onConfirm = () => undefined,
        eventName = "",
        title = "",
        description = <></>,
        cancelText = '',
        confirmText = '',
    } = props;

    const [participant, setParticipant] = useLocalStorage("participant", {}  );
    const [openEmail,   setOpenEmail  ] = useLocalStorage("openEmail",   null);

    const event = eventName || title;


    const handleClose = () => {
        logEvent(participant._id, `frontend___${pageName}_dialog_closed`, {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
                "dialog"            : event,
            }
        )
        onClose();
    }

    const handleConfirm = () => {
        logEvent(participant._id, `frontend___${pageName}_dialog_confirmed`, {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
                "dialog"            : event,
            }
        )
        onConfirm();
    }

    const handleEnter = () => {
        logEvent(participant._id, `frontend___${pageName}_dialog_opened`, {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
                'dialog'            : event,
            }
        )
        onOpen();
    }

    return (
        <Dialog
            open={open}
            onTransitionEnter={handleEnter}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogTitle id="alert-dialog-title">
                {title !== '' && title}
            </DialogTitle>

            <DialogContent>
                <DialogContentText id = "alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                { cancelText  !== '' && <Button onClick = {handleClose  }          > {cancelText } </Button> }
                { confirmText !== '' && <Button onClick = {handleConfirm} autoFocus> {confirmText} </Button> }

            </DialogActions>

        </Dialog>
    )
}