import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Title } from '../base/typography';

export const backButton = {
    text: "Back",
    action() {
        return this.back();
    },
    secondary: true
}

export const nextButton = {
    text: "Next",
    action() {
        return this.next();
    },
}

export const doneButton = {
    text: "Let's start",
    action() {
        return this.complete();
    },
}

export const standardButtons = [
    backButton,
    nextButton
]

export const defaultTourOptions = {
    defaultStepOptions: {
        canClickTarget: false,
        buttons: standardButtons,
        title: "Study Tutorial",
    },
    useModalOverlay: true,
}

export const tutorialDialogDescription = <>
    <span
        style={{
            fontSize: "2rem",
        }}
    >
        Study Instructions
    </span><br/><br/><br/>

    <span>
        Handle Jordan's emails within the time limit:
    </span><br/><br/>

    <span>
        • <b>Legitimate emails</b>:
        Manage them by clicking on the link. Jordan will then visit the website and perform their job <small>
            <i>
                (for example, check on social media, verify shipping information, act on a shared document)
            </i>
        </small>.
    </span><br/><br/>

    <span>
        • <b>Suspicious emails</b>:
        You can report suspicious emails to IT to manage them instead of clicking on their link.
    </span><br/><br/><br/>

    Remember: clicking on links <b>will NOT take you to any external website</b>.
    <br/>
    This is a roleplay, and only Jordan will visit the website and do their job there.
</>


// Blocking Warnings -------------------------------------------------------------------------------------------------

export const defaultBlockingWarningsTourOptions = {
    defaultStepOptions: {
        canClickTarget: false,
        buttons: standardButtons,
        title: "Suspicious Email Indicators",
        cancelIcon: {
            enabled: true
        }
    },
    useModalOverlay: true,
}

export const blockingWarningDoneButton = {
    text: "Close",
    action() {
        return this.complete();
    },
}
