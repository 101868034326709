import { Button, Grid, Box, Typography, Container, List, ListItem, ListItemText } from '@mui/material';
import EmailViewer from '../components/mailbox/EmailViewer';
import Timer from '../components/framework/Timer';
import { useState, useEffect, useMemo, useRef } from 'react';
import { BACKEND_ADDRESS } from '../App';
import { ExperimentProgress, baseExperimentStatus, Pages, ExperimentModes, getActiveElement } from '../Types';
import { EmailTypes } from '../Types';
import { logEvent } from '../networking';
import ProfilePicture from '../components/persona/ProfilePicture';
import { MailboxButton, MailboxButtonSmall } from '../components/mailbox/MailboxButton';
import { Phishing, MarkEmailUnread, Archive, Help, CastForEducation, LocalShipping } from '@mui/icons-material';
import { Quickreply, Groups, Launch, CardGiftcard, Newspaper, Announcement } from '@mui/icons-material';
import { InsertInvitation, Preview, Report, CheckBox } from '@mui/icons-material';
import EmailsList from '../components/mailbox/EmailsList';
import MailboxButtons from '../components/mailbox/MailboxButtons'
import SelectEmail from '../components/mailbox/SelectEmail';
import LoggerDialog from '../components/dialogs/LoggerDialog';
import { useLocalStorage } from '@uidotdev/usehooks';
import { defaultTourOptions, nextButton, backButton, doneButton } from '../components/llmtraining/Tutorials';
import { tutorialDialogDescription } from '../components/llmtraining/Tutorials';
import { defaultBlockingWarningsTourOptions, blockingWarningDoneButton } from '../components/llmtraining/Tutorials';
import { Preformatted } from '../components/base/typography';
import TaskProgress from '../components/framework/TaskProgress';
import Shepherd from 'shepherd.js';
import MCHeader from '../components/llmtraining/MailClient/Header';
import MCFooter from '../components/llmtraining/MailClient/Footer';
import MCLLMChat from '../components/llmtraining/MailClient/LLMChat';
import MCEmailClues from '../components/llmtraining/MailClient/EmailClues';

import 'shepherd.js/dist/css/shepherd.css';


export default function MailClient() {
    // This tells the UI to either render the standard version of the MailClient buttons (similar to the way the
    // previous study was done), or to use multiple different buttons for classifying the email as "News", "Promotions",
    // etc, hiding the real idea of the study.
    const use10Buttons = false;




    const [participant, setParticipant] = useLocalStorage("participant", {});

    const [mailList,  setMailList ] = useLocalStorage("emails",    []  );
    const [mailCount, setMailCount] = useLocalStorage("mailCount", 0   );
    const [openEmail, setOpenEmail] = useLocalStorage("openEmail", null);

    const [leaveDialogOpen,    setLeaveDialogOpen   ] = useState(false);
    const [roleplayDialogOpen, setRoleplayDialogOpen] = useState(false);
    const [handledDialogOpen,  setHandledDialogOpen ] = useState(false);
    const [reportedDialogOpen, setReportedDialogOpen] = useState(false);
    const [tutorialDialogOpen, setTutorialDialogOpen] = useState(false);
    const [tutorialOpen,       setTutorialOpen      ] = useState(false);

    const [employee, setEmployee] = useLocalStorage("employee", {});
    const [company,  setCompany ] = useLocalStorage("company",  {});

    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);
    const [returnedFromTrainingPage, setReturnedFromTrainingPage] = useLocalStorage("returnedFromTrainingPage", false);
    const [wentToTrainingPageThroughDebugging, setWentToTrainingPageThroughDebugging] = useLocalStorage("wentToTrainingPageThroughDebugging", false);

    const emailBodyDom = useRef(null);

    const tour = useMemo(() => {
        let tour = new Shepherd.Tour(defaultTourOptions);
        tour.on('complete', () => {
            setTutorialOpen(false);
            setExperiment({ ...experiment, mailboxOnboarded: true });
        });
        tour.on('cancel', () => {
            setTutorialOpen(false);
        });
        tour.on('start', () => {
            setTutorialOpen(true);
        });

        const mailclientSteps = [
            {
                text: "You will now be walked through the process of the study",
                buttons: [ nextButton ]
            },
            {
                text: "This is your inbox: you can select an email to open in the viewer and display its content",
                attachTo: { element: '.mailbox-tutorial-emailsList', on: 'right' },
            },
        ];

        const stepMailClientButtons = [];

        if (use10Buttons === true) {
            const stepsFor10Buttons = [
                {
                    text: "You will have to manage the emails you received by classifying them in the following"
                            + " categories depending on their content",
                    attachTo: { element: '.mailbox-tutorial-buttonGroupLegitimate', on: 'bottom' },
                    when: { 
                        show: () => {
                            setOpenEmail(mailList[0])
                        } 
                    }
                },
                {
                    text: "Suspicious emails can be reported to the IT department by clicking on " +
                            "the Review of Report buttons",
                    attachTo: { element: '.mailbox-tutorial-buttonGroupSuspicious', on: 'bottom' },
                },
            ];

            stepMailClientButtons.push(...stepsFor10Buttons);
        }
        else {
            const stepsForNormalButtons = [
                {
                    text: "You will have to manage the emails you received by <b>clicking on their link</b>",
                    attachTo: { element: '.mailbox-tutorial-emailContainer', on: 'left' },
                    when: { 
                        show: () => {
                            setOpenEmail(mailList[0])
                        } 
                    }
                },
                { 
                    text: "Note that this is a roleplay and <b>clicking on the link will NOT take you anywhere</b>"
                           + " - your roleplay character will perform their job on that website",
                },
                {
                    text: "If an email looks suspicious, it can be reported to the IT department by clicking on " +
                            "the \"Report Suspicious\" button",
                    attachTo: { element: '.mailbox-tutorial-buttonReportPhishing', on: 'bottom' },
                },
            ];

            stepMailClientButtons.push(...stepsForNormalButtons);
        }
        
        mailclientSteps.push(...stepMailClientButtons);

        if (getActiveElement(participant.group, "Right-hand side clues widget") === true) {
            const stepEmailClueWidget = {
                text: "The following clue list can help you with the classification of the email",
                attachTo: { element: '.mailbox-tutorial-clueWidget', on: 'left' },
            };
            mailclientSteps.push(stepEmailClueWidget);
        }
        
        if (getActiveElement(participant.group, "Right-hand side LLM chat") === true) {
            const StepLLMChatWidget = {
                text: "Furthermore, you can also use the following AI chat assistant to ask for further help",
                attachTo: { element: '.mailbox-tutorial-LLMChat', on: 'left' },
            };
            mailclientSteps.push(StepLLMChatWidget);
        }

        const StepProfilePicture = {
            text: "If you would like to be reminded of your role in the company and its details you can click on " + 
                    "your profile picture",
            attachTo: { element: '.mailbox-tutorial-buttonProfilePicture', on: 'bottom' },
        };
        mailclientSteps.push(StepProfilePicture);

        
        // Modify the last element of mailclientSteps
        mailclientSteps[mailclientSteps.length - 1].buttons = [ 
            backButton, 
            doneButton
        ];
        mailclientSteps[mailclientSteps.length - 1].when = { show: () => { setOpenEmail(null) } }


        tour.addSteps(mailclientSteps);
        return tour;
    }, [mailList]);

    const roleplayDescription = useMemo(() => (
        <span>
            <br/>
            <span>
                • You are {employee.name} {employee.surname}.
                Your responsibilities are <b>{employee.responsibilities[0]} and {employee.responsibilities[1]}</b>.
            </span><br/><br/>
            <span>
                • {company.name}'s domains are
                <Preformatted>{company.domains[0]}</Preformatted> and <Preformatted>{company.domains[1]}</Preformatted>
            </span><br/><br/>
            <span>
                • Personal corporate email addresses: <Preformatted>name.surname@{company.domains[0]}</Preformatted>
            </span><br/><br/>
            <span>
                • {company.name} document and communication tools:
                Google Drive (<Preformatted>drive.google.com</Preformatted>),
                OneDrive / Sharepoint (<Preformatted>sharepoint.com</Preformatted>),
                Microsoft Teams (<Preformatted>teams.microsoft.com</Preformatted>)
            </span><br/><br/>
        </span>
    ), [company]);

    const handleListClick = (event, email) => {
        logEvent(participant._id, "frontend___mailClient_mail_selected", {
                "email_id"          : email ? email._id             : "",
                "email_description" : email ? email.log_description : "",
            }
        );
        setMailList(mailList.map((mail) => {
            if (mail._id === email._id) {
                mail.read = true;
                mail.selected = true;
            }
            else {
                mail.selected = false;
            }
            return mail;
        }));
        setOpenEmail(email);
    };


    // STEP :: 1 --------------------------------------------------------------------------------------------
    // Handlers for the different buttons

    // Debugging Only
    const handleGotoDebuggingPage = () => {
        logEvent(participant._id, "frontend___mailClient_debuggingMode_buttonClicked_gotoDebuggingPage", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setExperiment({ ...experiment, page: Pages.DEBUGGING_PAGE });
    };

    // Debugging Only
    const handleGotoTrainingPage = () => {
        logEvent(participant._id, "frontend___mailClient_debuggingMode_buttonClicked_gotoTrainingPage", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setWentToTrainingPageThroughDebugging(true);
        setExperiment({ ...experiment, page: Pages.TRAINING_PAGE });
    };

    // Debugging Only
    const handleGotoTrainingPagePost = () => {
        logEvent(participant._id, "frontend___mailClient_debuggingMode_buttonClicked_gotoTrainingPagePost", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setExperiment({ ...experiment, page: Pages.TRAINING_PAGE_POST });
    };

    const handleMarkUnread = () => {
        logEvent(participant._id, "frontend___mailClient_buttonClicked_markUnread", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setMailList(mailList.map((mail) => {
            if (mail._id === openEmail._id) {
                mail.read = false;
                mail.selected = false;
            }
            return mail;
        }));
        setOpenEmail(null);
    };

    const handlePhishingReport = () => {
        logEvent(participant._id, "frontend___mailClient_buttonClicked_phishingReport", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setReportedDialogOpen(true);
    };

    const handleDisplayTutorialDialog = () => {
        logEvent(participant._id, "frontend___mailClient_buttonClicked_displayTutorialDialog", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );

        setTutorialDialogOpen(true);
    };


    // STEP :: 1 --------------------------------------------------------------------------------------------
    // Handlers for the 10 different classification buttons for the email ("News", "Offers", etc.)

    const handleArchive = () => {
        logEvent(participant._id, "frontend___mailClient_10buttons_buttonClicked_archive", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        handleProcessedEmail();
    };

    const handleInternalCommunication = () => {
        logEvent(participant._id, "frontend___mailClient_10buttons_buttonClicked_internalCommunication", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        handleClassifiedAsLegitimate();
    };

    const handleExternalCommunication = () => {
        logEvent(participant._id, "frontend___mailClient_10buttons_buttonClicked_externalCommunication", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        handleClassifiedAsLegitimate();
    };

    const handlePromotions = () => {
        logEvent(participant._id, "frontend___mailClient_10buttons_buttonClicked_promotions", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        handleClassifiedAsLegitimate();
    };

    const handleNewsletters = () => {
        logEvent(participant._id, "frontend___mailClient_10buttons_buttonClicked_newsletters", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        handleClassifiedAsLegitimate();
    };

    const handleUpdates = () => {
        logEvent(participant._id, "frontend___mailClient_10buttons_buttonClicked_updates", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        handleClassifiedAsLegitimate();
    };

    const handleInvitations = () => {
        logEvent(participant._id, "frontend___mailClient_10buttons_buttonClicked_invitations", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        handleClassifiedAsLegitimate();
    };

    const handleReviewNeeded = () => {
        logEvent(participant._id, "frontend___mailClient_10buttons_buttonClicked_reviewNeeded", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        handleClassifiedAsPhishing();
    };

    const handle10ButtonsPhishingReport = () => {
        logEvent(participant._id, "frontend___mailClient_10buttons_buttonClicked_phishingReport", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setReportedDialogOpen(true);
    };


    // STEP :: 1 --------------------------------------------------------------------------------------------
    // Listeners for the links inside the email body

    let listeners = [
        {
            "type": "click",
            "selector": "a",
            "callback": async (event) => {
                event.preventDefault();
                await logEvent(participant._id, "frontend___mailClient_url_clicked", {
                        "email_id"          : openEmail ? openEmail._id             : "",
                        "email_description" : openEmail ? openEmail.log_description : "",
                    }
                );
                setHandledDialogOpen(true);
            }
        },
        {
            "type": "contextmenu",
            "selector": "a",
            "callback": async (event) => {
                event.preventDefault();
                await logEvent(participant._id, "frontend___mailClient_url_rightButtonContextMenu_clicked", {
                        "email_id"          : openEmail ? openEmail._id             : "",
                        "email_description" : openEmail ? openEmail.log_description : "",
                    }
                );
            }
        },
        {
            "type": "auxclick",
            "selector": "a",
            "callback": async (event) => {
                if (event.button === 1) {
                    event.preventDefault();
                    await logEvent(participant._id, "frontend___mailClient_url_middleWheelButton_clicked", {
                            "email_id"          : openEmail ? openEmail._id             : "",
                            "email_description" : openEmail ? openEmail.log_description : "",
                        }
                    );
                }
            }
        }
    ];


    // STEP :: 2 --------------------------------------------------------------------------------------------
    // Handlers for the "URL" and "Report" pop-up logging window

    const handleHandledEmailConfirm = () => {
        logEvent(participant._id, "frontend___mailClient_url_clickConfirmed", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setHandledDialogOpen(false);
        handleClassifiedAsLegitimate();
    };

    const handleReportPhishingConfirm = () => {
        logEvent(participant._id, "frontend___mailClient_phishingReport_clickConfirmed", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setReportedDialogOpen(false);
        handleClassifiedAsPhishing();
    };


    // STEP :: 3 --------------------------------------------------------------------------------------------
    // Handlers for after the emails have been classified as either legitimate or phishing

    const handleClassifiedAsLegitimate = () => {
        logEvent(participant._id, "frontend___mailClient_classified_asLegitimate", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        if (openEmail.email_type === EmailTypes.LEGITIMATE) {
            handleCorrectlyClassified();
        } 
        else {
            handleIncorrectlyClassified();
        }
    };

    const handleClassifiedAsPhishing = () => {
        logEvent(participant._id, "frontend___mailClient_classified_asPhishing", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        if (openEmail.email_type === EmailTypes.PHISHING) {
            handleCorrectlyClassified();
        } 
        else {
            handleIncorrectlyClassified();
        }
    };


    // STEP :: 4 --------------------------------------------------------------------------------------------
    // Log Classification outcome Handlers
    // Handlers for the "Correctly Classified" and "Incorrectly Classified" pop-up logging window
    // If it is not in Debugging mode, those are called directly by the classified handlers

    const handleCorrectlyClassified = () => {
        logEvent(participant._id, "frontend___mailClient_classified_correctly", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        handleProcessedEmail();
    };

    const handleIncorrectlyClassified = () => {
        logEvent(participant._id, "frontend___mailClient_classified_incorrectly", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );

                
        if (    // Part of the participant groups that have a Training Page
              ( getActiveElement(participant.group, "Training page") ||
                getActiveElement(participant.group, "Training page with details buttons") ||
                getActiveElement(participant.group, "Training page with LLM chat") ) 
             &&
                // If the email is phishing (which means it was incorrectly classified as legitimate)
              ( openEmail.email_type === EmailTypes.PHISHING )
            )
        {
            logEvent(participant._id, "frontend___mailClient_gotoTrainingPage", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                }
            );
            setWentToTrainingPageThroughDebugging(false);
            setExperiment({ ...experiment, page: Pages.TRAINING_PAGE });
        } 
            else 
        {
            handleProcessedEmail();
        }
    };

    useEffect(() => {
        if(returnedFromTrainingPage) {
            setReturnedFromTrainingPage(false);
            if (!wentToTrainingPageThroughDebugging) {
                handleProcessedEmail();
            }
        }
    }, [returnedFromTrainingPage]);


    // STEP :: 5 --------------------------------------------------------------------------------------------
    function handleProcessedEmail() {
        logEvent(participant._id, "frontend___mailClient_mail_processed", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        let remainingEmails = mailList.filter(email => email._id !== openEmail._id);
        setMailList(remainingEmails);
        setOpenEmail(null);

        if (remainingEmails.length === 0) {
            logEvent(participant._id, "frontend___mailClient_leave_finished", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                }
            );
            setExperiment({ ...experiment, status: ExperimentProgress.OVER, page: Pages.DEBRIEFING });
        }
    }


    // STEP :: 6 --------------------------------------------------------------------------------------------
    // Ending conditions for the experiment when there are still unprocessed emails

    const handleLeave = () => {
        logEvent(participant._id, "frontend___mailClient_leave_early", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setLeaveDialogOpen(false);
        setExperiment({ ...experiment, status: ExperimentProgress.LEFT_EARLY, page: Pages.DEBRIEFING })
    };

    const handleTimeout = () => {
        logEvent(participant._id, "frontend___mailClient_leave_timeout", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setExperiment({ ...experiment, status: ExperimentProgress.TIMEOUT, page: Pages.DEBRIEFING })
    };


    // Roleplay dialog (The persona's picture)
    const handleRoleplayDialog = () => {
        logEvent(participant._id, "frontend___mailClient_roleplay_activated", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setRoleplayDialogOpen(true);
    };


    // This starts the tutorial
    const showTour = () => {
        if (!tour.isActive() && !tutorialOpen) {
            tour.start();
        }
    }

    // This shows the tutorial at the start of the experiment
    if (!experiment.mailboxOnboarded && mailCount != 0) {
        showTour();
    }


    useEffect(() => {

        if (experiment.status === ExperimentProgress.NOT_STARTED && mailCount === 0) {
            setExperiment({ ...experiment, status: ExperimentProgress.STARTED });
            logEvent(participant._id, "frontend___mailClient_mailList_loading", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                }
            );
            fetch(`${BACKEND_ADDRESS}/emails/user/${participant._id}`, {
                method: 'GET',
                headers: { 'Content-type': 'application/json' },
            })
                .then(async (res) => {
                    logEvent(participant._id, "frontend___mailClient_mailList_loaded", {
                            "email_id"          : openEmail ? openEmail._id             : "",
                            "email_description" : openEmail ? openEmail.log_description : "",
                        }
                    );
                    const res_json = await res.json();

                    // Sort res_json array by timestamp, ascending
                    let emails = res_json.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
                    const times = [
                                    '08:14', '08:22', '08:28', '08:31',
                                    '08:37', '08:45', '08:49', '08:57',
                                    '09:02', '09:12', '09:18', '09:23',
                                    '09:36', '09:44', '09:59', '10:05',
                                    '10:25', '10:41', '10:48', '10:53',
                                    '99:99' // If there are too many emails while debugging, return '99:99'
                    ]
                    
                    emails = res_json.map((email, index) => {
                        const timestampIndex = index < times.length ? index : times.length - 1;
                        email.timestamp = times[timestampIndex];
                        return email;
                    });

                    // if (experiment.experimentMode === ExperimentModes.DEBUGGING) {
                    //     emails = res_json.map((email, index) => {
                    //         if (email.email_type === EmailTypes.LEGITIMATE) {
                    //             email.subject = "[LEGITIMATE] " + email.subject;
                    //         } else {
                    //             email.subject = "[PHISHING] "   + email.subject;
                    //         }
                    //         return email;
                    //     });
                    // }

                    if (res.status === 200) {
                        setMailList(emails);
                        setMailCount(emails.length);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    });

    const handleCluesTourFromBodyDom = (shadowDomEmailBody) => {
        let cluesTourLocal = new Shepherd.Tour(defaultBlockingWarningsTourOptions);

        if (openEmail != null) {
            let email = mailList.filter(email => email._id === openEmail._id)[0];

            let cluesStepsStr = email.clues_steps_str;
        
            let cluesStepsBackend = JSON.parse(cluesStepsStr);
            
            // First tour step. Explains to the user what the blocking warnings are
            let introductoryStep = {
                text: "The following clues can help you correctly evaluate this email's legitimacy.",
                buttons: [
                    nextButton
                ]
            };
            cluesTourLocal.addStep(introductoryStep, 0);

            // List of blocking warnings, attached to the respective email element
            for (let i = 0; i < cluesStepsBackend.length; i++) {
                const clueStepBackend = cluesStepsBackend[i];

                let attachToElement = {
                    element: 
                        clueStepBackend.isInShadowRoot === "true" ?
                        shadowDomEmailBody.current.querySelector(clueStepBackend.label) :
                        clueStepBackend.label,
                    on: 'right',
                };

                let clueDetailsButton = {
                    text: "Details",
                    action() {
                        logEvent(participant._id, "frontend___mailClient_blockingWarnings_clueDetails_activated", {
                                "email_id"          : openEmail ? openEmail._id : "",
                                "email_description" : openEmail ? openEmail.log_description : "",
                                "clue_index"        : i,
                                "clue_text"         : clueStepBackend.text,
                                "clue_details"      : clueStepBackend.details,
                            }
                        );
                        this.addStep({
                            // Details button shows:
                            //          "Bold clue text + New line + Normal details"
                            text: (
                                "<b>" + clueStepBackend.text + "</b>" +
                                "<br>" + "<br>" +
                                clueStepBackend.details
                            ),
                            attachTo: attachToElement,
                            buttons: [
                                backButton,
                                nextButton
                            ]
                        }, i+2);
                        this.show(i+2);
                        return this.removeStep('normalText-'+(i+1));
                    },
                    classes: 'shepherd-tour-details-button',
                }

                let buttons = [
                    clueDetailsButton,
                    backButton,
                    nextButton,
                ];

                if (i === cluesStepsBackend.length-1) {
                    buttons = [
                        clueDetailsButton,
                        backButton,
                        blockingWarningDoneButton
                    ];
                }

                // If there is no rephrasing, we remove the first button
                if (getActiveElement(participant.group, "Overlay, details") === false) {
                    buttons.shift();
                }

                let clueStep = {
                    text: clueStepBackend.text,
                    id: 'normalText-'+(i+1),
                    attachTo: attachToElement,
                    buttons: buttons,
                };

                cluesTourLocal.addStep(clueStep, i+1);
            }
        }

        return cluesTourLocal;
    };

    const handleShadowDomCreated = (shadowDomEmailBody) => {
        emailBodyDom.current = shadowDomEmailBody;
    };

    const displayBlockingWarnings = () => {
        logEvent(participant._id, "frontend___mailClient_blockingWarnings_activated", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        let cluesTour = new Shepherd.Tour(defaultBlockingWarningsTourOptions);
        if(emailBodyDom.current) {
            cluesTour = handleCluesTourFromBodyDom(emailBodyDom);
        }
        cluesTour.start();
    };


    return (
        <Box className="mailbox-container">
            <Box
                sx={{
                    height: '10.5vh',
                    maxHeight: '10.5vh',
                    overflow: 'auto' 
                }}
            >
                <Box className="mailbox-header">
                    <MCHeader name={company.name} logo={company.logo}>

                        <Button
                            variant="outline"
                            onClick={(event) => setLeaveDialogOpen(true)}
                            sx={{ color: "white", border: "1px white solid" }}
                        >
                            <Typography>Leave the experiment.</Typography>
                        </Button>

                        <TaskProgress
                            progress={mailCount - mailList.length}
                            total={mailCount} />

                        <Timer
                            minutes={parseInt(process.env.REACT_APP_EXPERIMENT_TIMER) || 20}
                            onTimeout={handleTimeout}
                            paused={tour.isActive() || tutorialOpen}
                        />

                        <Button
                            onClick={() => handleRoleplayDialog()}
                            color='inherit'
                            className={"mailbox-tutorial-buttonProfilePicture"}
                        >
                            <ProfilePicture employee={employee} company={company} />
                        </Button>

                    </MCHeader>
                </Box>
            </Box>


            <Grid
                sx={{
                    display: 'grid',
                    gridTemplateColumns: (
                        (
                            getActiveElement(participant.group, "Right-hand side clues widget") ||
                            getActiveElement(participant.group, "Right-hand side details") ||
                            getActiveElement(participant.group, "Right-hand side LLM chat") ||
                            getActiveElement(participant.group, "Overlay, basic (blocking warnings)") ||
                            getActiveElement(participant.group, "Overlay, details")
                        ) ?
                        '67% 33%' : '100% 0%'
                    ),
                    height: '87.5vh',
                    maxHeight: '87.5vh',
                    overflow: 'auto'
                }}
            >
                <Grid
                    sx={{
                        height: '100%',
                        maxHeight: '100%',
                        overflow: 'auto'
                    }}
                >
                    <Grid
                        sx={{
                            height: '17%',
                            maxHeight: '17%',
                            overflow: 'auto'
                        }}
                    >
                        <Box className="mailbox-buttons">
                            {
                                use10Buttons &&
                                <>
                                    <MailboxButtons
                                        sx={{
                                            overflow: 'auto'
                                        }}
                                    >
                                        {/* {
                                            experiment.experimentMode === ExperimentModes.DEBUGGING &&
                                            <>
                                                <MailboxButtonSmall
                                                    image={<CastForEducation fontSize='medium' />}
                                                    callback={handleGotoTrainingPage}
                                                    disabled={!openEmail}
                                                    sx={{
                                                        boxSizing: 'border-box',
                                                        border: '5px solid red',
                                                    }}
                                                >
                                                    Training Page
                                                </MailboxButtonSmall>
                                                <MailboxButtonSmall
                                                    image={<Quickreply fontSize='medium' />}
                                                    callback={handleGotoDebuggingPage}
                                                    sx={{
                                                        boxSizing: 'border-box',
                                                        border: '5px solid red',
                                                    }}
                                                >
                                                    Debugging
                                                </MailboxButtonSmall>
                                            </>
                                        } */}
                                        <MailboxButtonSmall
                                            image={<MarkEmailUnread fontSize='medium' />}
                                            callback={handleMarkUnread}
                                            disabled={!openEmail}
                                        >
                                            Mark Unread
                                        </MailboxButtonSmall>
                                        <MailboxButtonSmall
                                            image={<Archive fontSize='medium' />}
                                            callback={handleArchive}
                                            disabled={!openEmail || openEmail.url !== null}
                                            className={"mailbox-tutorial-buttonArchive"}
                                        >
                                            Archive
                                        </MailboxButtonSmall>
                                        <MailboxButtonSmall
                                            image={<Help fontSize='medium' />}
                                            callback={handleDisplayTutorialDialog}
                                            className={"mailbox-tutorial-buttonHelp"}
                                        >
                                            Help
                                        </MailboxButtonSmall>
                                        
                                    </MailboxButtons>
                                    <MailboxButtons
                                        sx={{
                                            overflow: 'auto'
                                        }}
                                    >
                                        <Box
                                            className="mailbox-tutorial-buttonGroupLegitimate"
                                        >
                                            <MailboxButtonSmall
                                                image={<Groups fontSize='medium' />}
                                                callback={handleInternalCommunication}
                                                disabled={!openEmail}
                                                className={"mailbox-tutorial-buttonInternalCommunication"}
                                            >
                                                Internal Communication
                                            </MailboxButtonSmall>
                                            <MailboxButtonSmall
                                                image={<Launch fontSize='medium' />}
                                                callback={handleExternalCommunication}
                                                disabled={!openEmail}
                                                className={"mailbox-tutorial-buttonExternalCommunication"}
                                            >
                                                External Communication
                                            </MailboxButtonSmall>
                                            <MailboxButtonSmall
                                                image={<CardGiftcard fontSize='medium' />}
                                                callback={handlePromotions}
                                                disabled={!openEmail}
                                                className={"mailbox-tutorial-buttonPromotions"}
                                            >
                                                Promotions
                                            </MailboxButtonSmall>
                                            <MailboxButtonSmall
                                                image={<Newspaper fontSize='medium' />}
                                                callback={handleNewsletters}
                                                disabled={!openEmail}
                                                className={"mailbox-tutorial-buttonNewsletters"}
                                            >
                                                Newsletters
                                            </MailboxButtonSmall>
                                            <MailboxButtonSmall
                                                image={<Announcement fontSize='medium' />}
                                                callback={handleUpdates}
                                                disabled={!openEmail}
                                                className={"mailbox-tutorial-buttonUpdates"}
                                            >
                                                Updates
                                            </MailboxButtonSmall>
                                            <MailboxButtonSmall
                                                image={<InsertInvitation fontSize='medium' />}
                                                callback={handleInvitations}
                                                disabled={!openEmail}
                                                className={"mailbox-tutorial-buttonInvitations"}
                                            >
                                                Invitations
                                            </MailboxButtonSmall>
                                        </Box>

                                        <Box
                                            className="mailbox-tutorial-buttonGroupSuspicious"
                                        >
                                            <MailboxButtonSmall
                                                image={<Preview fontSize='medium' />}
                                                callback={handleReviewNeeded}
                                                disabled={!openEmail}
                                                className={"mailbox-tutorial-buttonReviewNeeded"}
                                            >
                                                Review Needed
                                            </MailboxButtonSmall>
                                            <MailboxButtonSmall
                                                image={<Report fontSize='medium' />}
                                                callback={handle10ButtonsPhishingReport}
                                                disabled={!openEmail}
                                                className={"mailbox-tutorial-button10ButtonsReportPhishing"}
                                            >
                                                Report Phishing
                                                {/* Used to be "Report Suspicious" */}
                                            </MailboxButtonSmall>
                                        </Box>
                                    </MailboxButtons>
                                </>
                            }
                            {
                                !use10Buttons &&
                                <>
                                    <MailboxButtons
                                        sx={{
                                            overflow: 'auto'
                                        }}
                                    >
                                        {/* {
                                            experiment.experimentMode === ExperimentModes.DEBUGGING &&
                                            <>
                                                <MailboxButton
                                                    image={<CastForEducation fontSize='large' />}
                                                    callback={handleGotoTrainingPage}
                                                    disabled={!openEmail}
                                                    sx={{
                                                        boxSizing: 'border-box',
                                                        border: '5px solid red',
                                                    }}
                                                    fontSize='small'
                                                >
                                                    Training Page
                                                </MailboxButton>
                                                <MailboxButton
                                                    image={<Quickreply fontSize='large' />}
                                                    callback={handleGotoDebuggingPage}
                                                    sx={{
                                                        boxSizing: 'border-box',
                                                        border: '5px solid red',
                                                    }}
                                                    fontSize='small'
                                                >
                                                    Debugging
                                                </MailboxButton>
                                            </>
                                        } */}
                                        <MailboxButton
                                            image={<MarkEmailUnread fontSize='large' />}
                                            callback={handleMarkUnread}
                                            disabled={!openEmail}
                                            fontSize='small'
                                        >
                                            Mark Unread
                                        </MailboxButton>
                                        <MailboxButton
                                            image={<Phishing fontSize='large' />}
                                            callback={handlePhishingReport}
                                            disabled={!openEmail}
                                            className={"mailbox-tutorial-buttonReportPhishing"}
                                            fontSize='small'
                                        >
                                            Report Suspicious
                                        </MailboxButton>
                                        <MailboxButton
                                            image={<Help fontSize='large' />}
                                            callback={handleDisplayTutorialDialog}
                                            className={"mailbox-tutorial-buttonHelp"}
                                            fontSize='small'
                                        >
                                            Help
                                        </MailboxButton>
                                    </MailboxButtons>
                                </>
                            }
                            {/* Those are buttons that are no-longer displayed */}
                            {/* {
                                <MailboxButton
                                    image={<Archive fontSize='large' />}
                                    callback={handleArchive}
                                    disabled={!openEmail || openEmail.url !== null}
                                    className={"mailbox-tutorial-buttonArchive"}
                                >
                                    Archive
                                </MailboxButton>
                            } */}
                            {/* {
                                <MailboxButton
                                    image={<LocalShipping fontSize='large' />}
                                    callback={handleGotoTrainingPagePost}
                                    disabled={!openEmail}
                                >
                                    Training Page for Post
                                </MailboxButton>
                            } */}
                        </Box>
                    </Grid>

                    <Grid
                        sx={{
                            height: '83%',
                            maxHeight: '83%',
                            overflow: 'auto'
                        }}
                    >
                        <Box 
                            className="mailbox-body"
                            sx={{
                                height: '100%',
                                maxHeight: '100%',
                                overflow: 'auto'
                            }}
                        >
                            <Box
                                className="mailbox-list-container"
                                sx={{
                                    height: '100%',
                                    maxHeight: '100%',
                                    overflow: 'auto'
                                }}
                            >
                                <Typography
                                    variant='h5'
                                    sx={{
                                        px: "1rem",
                                        height: '7%',
                                        maxHeight: '7%',
                                    }}
                                >
                                    Inbox
                                </Typography>
                                <Box
                                    className="mailbox-tutorial-emailsList"
                                    sx={{
                                        height: '93%',
                                        maxHeight: '93%',
                                        overflowY: 'auto'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            overflow: 'auto'
                                        }}
                                    >
                                        <EmailsList emails={mailList} clickHandler={handleListClick} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="mailbox-viewer-container mailbox-tutorial-emailContainer">
                                <Box className="mailbox-viewer" >
                                {
                                    openEmail 
                                        ?
                                    <EmailViewer
                                        email={openEmail}
                                        listeners={listeners}
                                        onShadowDomCreation={handleShadowDomCreated}
                                    />
                                        :
                                    <SelectEmail />
                                }
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                
                {
                    (
                        getActiveElement(participant.group, "Right-hand side clues widget") ||
                        getActiveElement(participant.group, "Right-hand side details") ||
                        getActiveElement(participant.group, "Right-hand side LLM chat") ||
                        getActiveElement(participant.group, "Overlay, basic (blocking warnings)") ||
                        getActiveElement(participant.group, "Overlay, details")
                    ) &&
                    <Grid
                        sx={{
                            height: '100%',
                            maxHeight: '100%',
                            overflow: 'auto'
                        }}
                    >
                        <Grid item xs={3} sx={{ height: '100%' }}>
                            {
                                getActiveElement(participant.group, "Right-hand side clues widget") &&
                                <Grid
                                    className={"mailbox-tutorial-clueWidget"}
                                    item
                                    xs={12}
                                    sx={{
                                        height:
                                            getActiveElement(participant.group, "Right-hand side LLM chat") ?
                                            '25%' : '100%',
                                        maxHeight:
                                            getActiveElement(participant.group, "Right-hand side LLM chat") ?
                                            '25%' : '100%',
                                        backgroundColor: "#eaeaea",
                                        paddingBottom: "10px",
                                        display: "flex",             // Added flex display
                                        alignItems: "center"         // Centering items vertically
                                    }}
                                >
                                    <MCEmailClues
                                        participant={participant}
                                        openEmail={openEmail}
                                        clickHandlerShowBlockingWarnings={
                                            getActiveElement(participant.group, "Overlay, basic (blocking warnings)") ?
                                            displayBlockingWarnings : null
                                        }
                                    />
                                </Grid>
                            }
                            {
                                getActiveElement(participant.group, "Right-hand side LLM chat") &&
                                <Grid
                                    className={"mailbox-tutorial-LLMChat"}
                                    item
                                    xs={12}
                                    sx={{
                                        height: '75%',
                                        maxHeight: '75%',
                                        backgroundColor: "#dadafe",
                                        paddingBottom: "10px",
                                        position:"relative",
                                        display: "flex",             // Added flex display
                                        alignItems: "center"         // Centering items vertically
                                    }}
                                >
                                    <MCLLMChat
                                        participant={participant}
                                        openEmail={openEmail}
                                        backend_address={BACKEND_ADDRESS}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                }
            </Grid>


            <Grid
                container
                sx={{
                    height: '2vh',
                    maxHeight: '2vh',
                    overflow: 'auto' 
                }}
            >
                <MCFooter/>
            </Grid>

            <LoggerDialog
                pageName    = "mailClient"
                open        = {roleplayDialogOpen}
                onClose     = {() => setRoleplayDialogOpen(false)}
                title       = "Roleplay information"
                description = {roleplayDescription}
                eventName   = "roleplay_dialog"
                cancelText  = "Ok"
            />

            <LoggerDialog
                pageName    = "mailClient"
                open        = {leaveDialogOpen}
                onConfirm   = {handleLeave}
                onClose     = {() => setLeaveDialogOpen(false)}
                title       = "Leave experiment"
                description = "Do you really want to leave? You will not be allowed to come back."
                eventName   = "leave_experiment"
                confirmText = "Leave"
                cancelText  = "Cancel"
            />

            <LoggerDialog
                pageName    = "mailClient"
                open        = {handledDialogOpen}
                onConfirm   = {handleHandledEmailConfirm}
                onClose     = {handleHandledEmailConfirm}
                title       = "Email handled"
                description = "You performed the required action on the linked website and can continue handling your other emails."
                eventName   = "email_handled"
                confirmText = "Ok"
            />

            <LoggerDialog
                pageName    = "mailClient"
                open        = {reportedDialogOpen}
                onConfirm   = {handleReportPhishingConfirm}
                onClose     = {() => setReportedDialogOpen(false)}
                title       = "Report email?"
                description = "Do you want to report this email to the IT department?"
                eventName   = "email_reported"
                confirmText = "Report"
                cancelText  = "Cancel"
            />

            <LoggerDialog
                pageName    = "mailClient"
                open        = {tutorialDialogOpen}
                onConfirm   = {() => setTutorialDialogOpen(false)}
                onClose     = {() => setTutorialDialogOpen(false)}
                title       = ""
                description = {tutorialDialogDescription}
                eventName   = "tutorial_dialog"
                confirmText = "Ok"
            />

        </Box>

    );
}