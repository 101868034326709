import { AppBar, Grid } from '@mui/material';


export default function TPFooter({ ...props }) {
    return (
        <Grid container>
            <AppBar position="sticky">
            </AppBar>
        </Grid>
    );
};
