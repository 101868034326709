import React, { useEffect, useState, useRef } from 'react';
import { Typography, Container, Paper, TextField, Button, ListItem, Grid } from '@mui/material'
import { CircularProgress, LinearProgress, Avatar } from '@mui/material'
import { theme } from '../../../theme';
import { logEvent } from '../../../networking';

// PartialServerMessage from the server
// which is received token by token
export class PartialServerMessage {
    constructor(message, isEnd){
        this.message = message;
        this.isEnd = isEnd;
    }
}

export default function MCLLMChat({participant, openEmail, backend_address, ...props}) {

    const KEY_CODE__ENTER = 13;

    const [messages, setMessages] = useState([]);
    const [conversationStarters, setConversationStarters] = useState([]);
    const [input, setInput] = useState('');
    // We cannot send messages while the server is replying
    const [isServerWriting, setIsServerWriting] = useState(false);
    const webSocket = useRef(null);
    const bottomOfChatRef = useRef(null);

    const llmImageIconPath = `images/llmIcons/robot.png`;

    const openWebSocket = () => {
        // WebSocket
        logEvent(participant._id, "frontend___mailClient_llmChat_websocket_opening", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
                "messages"          : JSON.stringify(messages),
            }
        );
        webSocket.current = new WebSocket("ws" + backend_address.slice(4) + "/llmapi_streaming");

        webSocket.current.onopen = (event) => {
            // Custom replacer function to handle circular references and other non-serializable properties
            const replacer = (key, value) => {
                // Omit properties that might cause circular references
                if (key === 'target' || key === 'srcElement' || key === 'currentTarget') {
                    return undefined;
                }
                return value;
            };

            // Stringify the event object using the custom replacer
            const eventString = JSON.stringify(event, replacer, 4); // Pretty-print with 4-space indentation

            logEvent(participant._id, "frontend___mailClient_llmChat_websocket_onOpenEvent", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify(messages),
                    "event"             : eventString,
                }
            );
        }
        webSocket.current.onclose = (event) => {
            // Custom replacer function to handle circular references and other non-serializable properties
            const replacer = (key, value) => {
                // Omit properties that might cause circular references
                if (key === 'target' || key === 'srcElement' || key === 'currentTarget') {
                    return undefined;
                }
                return value;
            };

            // Stringify the event object using the custom replacer
            const eventString = JSON.stringify(event, replacer, 4); // Pretty-print with 4-space indentation

            logEvent(participant._id, "frontend___mailClient_llmChat_websocket_onCloseEvent", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify(messages),
                    "event"             : eventString,
                }
            );
        }
    }

    const sanitizeHTML = (stringHTML) => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(stringHTML, 'text/html');
        let previewText = htmlDoc.body.innerText;
        previewText = previewText.replaceAll(/\n+|\t+|\s+/g, ' ');
        previewText = previewText.replaceAll(/\s+/g, ' ');
        return previewText;
    };

    const sanitizeEmail = (email) => {
        const clonedEmail = JSON.parse(JSON.stringify(email));
        clonedEmail.body = sanitizeHTML(clonedEmail.body);
        clonedEmail.subject = sanitizeHTML(clonedEmail.subject);
        return clonedEmail;
    };

    useEffect(() => {
        openWebSocket();

        return () => {
            logEvent(participant._id, "frontend___mailClient_llmChat_websocket_closing", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify(messages),
                }
            );
            webSocket.current.close();
        }
    }, []);

    useEffect(() => {
        if (openEmail) {
            // Add an initial system message
            setMessages([{ 
                role: 'system',
                content:
                    'You are a helpful assistant, which is very good at answering phishing-related questions. ' +
                    'You can only answer questions related to phishing emails. ' +
                    'Your answers are short. ' +
                    'The user will ask you questions about a specific email. ' +
                    'You cannot tell the user if the email is phishing or not. ' +
                    'The specific email is: ' +
                    JSON.stringify(sanitizeEmail(openEmail))
            }]);

            // Add a presentation message showing the participant what the LLM is used for
            setMessages(
                prevMessages => {
                    const presentationMessage = {
                        role: "assistant",
                        content:
                            "Greetings! I'm an AI assistant designed to help you distinguish between genuine " +
                            "emails and phishing attempts. Shall we get started?"
                    };
                    return [...prevMessages, presentationMessage];
                }
            );

            setConversationStarters(prevConversationStarters => []);
            for (let i = 0; i < openEmail.conversation_starters.length; i++) {
                setConversationStarters(prevConversationStarters => {
                    const newConversationStarter = {
                        id: i+1,
                        text: openEmail.conversation_starters[i]
                    };
                    return [...prevConversationStarters, newConversationStarter];
                })
            }

            // Close the WebSocket
            if (webSocket.current) {
                // Close WebSocket connection when email changes
                logEvent(participant._id, "frontend___mailClient_llmChat_websocket_emailChangeToAnotherEmail_closing", {
                        "email_id"          : openEmail ? openEmail._id             : "",
                        "email_description" : openEmail ? openEmail.log_description : "",
                        "messages"          : JSON.stringify(messages),
                    }
                );
                
                webSocket.current.close();
                setIsServerWriting(isServerWriting => false);
            }

            // Open a new WebSocket
            openWebSocket();
        } else {
            // Set the messages to be empty
            setMessages([]);

            // Clear up the conversation starter list
            setConversationStarters(prevConversationStarters => []);
            
            // Close the WebSocket
            if (webSocket.current) {
                // Close WebSocket connection when email changes
                logEvent(participant._id, "frontend___mailClient_llmChat_websocket_emailChangeToNull_closing", {
                        "email_id"          : openEmail ? openEmail._id             : "",
                        "email_description" : openEmail ? openEmail.log_description : "",
                        "messages"          : JSON.stringify(messages),
                    }
                );
                
                webSocket.current.close();
                setIsServerWriting(isServerWriting => false);
            }
        }
    }, [openEmail]);

    useEffect(() => {
        webSocket.current.onmessage = (event) => {
            const partialServerMessage = JSON.parse(event.data);
            setMessages(prevMessages => {
                if (partialServerMessage.isEnd === "true") {
                    logEvent(participant._id, "frontend___mailClient_llmChat_receivedServerMessage_finalPart", {
                            "email_id"          : openEmail ? openEmail._id             : "",
                            "email_description" : openEmail ? openEmail.log_description : "",
                            "messages"          : JSON.stringify(messages),
                        }
                    );
                    setIsServerWriting(isServerWriting => false);
                    return [...prevMessages]; // No change needed
                } else {
                    // // Far too verbose
                    // // Removed
                    // logEvent(participant._id, "frontend___mailClient_llmChat_receivedServerMessage_nonFinalPart", {
                    //         "email_id"          : openEmail ? openEmail._id             : "",
                    //         "email_description" : openEmail ? openEmail.log_description : "",
                    //         "response"          : partialServerMessage.message,
                    //     }
                    // );

                    const lastMessage = prevMessages[prevMessages.length - 1];
                    if (lastMessage && lastMessage.role === "assistant") {
                        // Append to last message
                        const updatedMessages = prevMessages.slice(0, -1); // Remove last message
                        lastMessage.content += partialServerMessage.message;
                        return [...updatedMessages, lastMessage];
                    } else {
                        // Add new server message
                        const newServerMessage = {
                            role: "assistant",
                            content: partialServerMessage.message
                        };
                        return [...prevMessages, newServerMessage];
                    }
                }
            });
        };
    }, [openEmail]);

    useEffect(() => {
        if(bottomOfChatRef.current) {
            bottomOfChatRef.current.scrollIntoView({ behavior: 'instant'});
        }
    }, [messages]);

    const handleSendMessage = () => {
        if (webSocket.current.readyState === WebSocket.OPEN && isServerWriting === false && input.trim() !== '') {
            logEvent(participant._id, "frontend___mailClient_llmChat_sendingMessageToServer_start", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify(messages),
                }
            );

            // The user can only send a single message
            // Afterwards, the server must respond
            setIsServerWriting(isServerWriting => true);

            // New message from the client
            const newUserMessage = {
                role: "user",
                content: input
            };

            // Add the new message to the list of messages
            setMessages(messages => [...messages, newUserMessage]);

            // Clean the input field
            setInput('');
            
            let messageForServer = JSON.stringify({
                "participant_id"    : participant._id,
                "email_id"          : openEmail ? openEmail._id : "",
                "email_description" : openEmail ? openEmail.log_description : "",
                "messages"          : JSON.stringify([...messages, newUserMessage])
            });

            // Log
            logEvent(participant._id, "frontend___mailClient_llmChat_sendingMessageToServer_start_content", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify([...messages, newUserMessage]),
                    "messages_newUserMessage" : newUserMessage["content"],
                    "messages_forServerAll"   : messageForServer,
                }
            );

            // Send to server
            webSocket.current.send(messageForServer);

            // Add a dummy empty message from the assistant
            setMessages(messages => [...messages, {
                role: 'assistant',
                content: ''
            }]);
        }
    };

    const handleConversationStarter = (text) => {
        if (webSocket.current.readyState === WebSocket.OPEN && isServerWriting === false) {
            logEvent(participant._id, "frontend___mailClient_llmChat_sendingConversationStarterToServer_start", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify(messages),
                }
            );

            // The user can only send a single message
            // Afterwards, the server must respond
            setIsServerWriting(isServerWriting => true);

            // New message from the client
            const newUserMessage = {
                role: "user",
                content: text
            };

            // Add the new message to the list of messages
            setMessages(messages => [...messages, newUserMessage]);
            
            let messageForServer = JSON.stringify({
                "participant_id"    : participant._id,
                "email_id"          : openEmail ? openEmail._id : "",
                "email_description" : openEmail ? openEmail.log_description : "",
                "messages"          : JSON.stringify([...messages, newUserMessage])
            });

            // Log
            logEvent(participant._id, "frontend___mailClient_llmChat_sendingConversationStarterToServer_start_content", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify([...messages, newUserMessage]),
                    "messages_newUserMessage" : newUserMessage["content"],
                    "messages_forServerAll"   : messageForServer,
                }
            );

            // Send to server
            webSocket.current.send(messageForServer);

            // Add a dummy empty message from the assistant
            setMessages(messages => [...messages, {
                role: 'assistant',
                content: ''
            }]);
        }
    };

    const handleKeyEnter = (event) => {
        if(event.keyCode === KEY_CODE__ENTER){
            logEvent(participant._id, "frontend___mailClient_llmChat_pressedEnterKey", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify(messages),
                }
            );
            handleSendMessage();
        }
    }

    const renderLLMNewLines = (content) => {
        const lines = content.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index !== lines.length - 1 && <br />}
            </React.Fragment>
        ));
    };

    return (
        <Container maxWidth="sm">
            <Paper 
                elevation={10}
                style={{
                    marginTop: '10px',
                    border: '5px solid '+theme.cluesPalette.primary,
                }}
            >
                <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: theme.palette.primary.main,
                        padding: '10px',
                        paddingLeft: '20px',
                        color: 'white'
                    }}
                >
                    <Avatar
                        src={llmImageIconPath}
                        style={{
                            width: '42px',
                            height: '42px',
                            marginLeft: '-7px',
                            marginRight: '20px',
                            border: '2px solid white',
                            borderRadius: '50%'
                        }}
                    />
                    AI Chat Assistant
                </Typography>

                <Grid
                    style={{
                        padding: '5px',
                    }}
                >
                    <div
                        style={
                            {
                                height: '300px',
                                maxHeight: '300px',
                                overflowY: 'scroll',
                                marginBottom: '0px'
                            }
                        }
                    >
                        {
                            messages.map(
                                (message, index) => {
                                    if (message.role === 'system') {
                                        return null;
                                    }
                                    
                                    return (
                                        <div
                                            key={index}
                                            style={
                                                {
                                                    display: 'flex',
                                                    justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
                                                    textAlign: message.role === 'user' ? 'right' : 'left',
                                                    marginRight: '5px'
                                                }
                                            }
                                        >
                                            <div>
                                                {message.role === 'assistant' && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Avatar
                                                            src={llmImageIconPath}
                                                            style={{
                                                                width: '36px',
                                                                height: '36px',
                                                                marginRight: '3px'
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                width: 0,
                                                                height: 0,
                                                                borderRight:
                                                                    '15px solid ' + theme.llmChatPalette.bot,
                                                                borderTop: '10px solid transparent',
                                                                borderBottom: '10px solid transparent',
                                                            }}
                                                        ></div>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                style={
                                                    {
                                                        maxWidth: '70%',
                                                        wordWrap: 'break-word',
                                                        backgroundColor:
                                                            message.role === 'user' ?
                                                            theme.llmChatPalette.user : theme.llmChatPalette.bot,
                                                        paddingLeft: '8px',
                                                        paddingRight: '8px',
                                                        paddingTop: '4px',
                                                        paddingBottom: '4px',
                                                        borderRadius: '5px',
                                                        marginBottom: '5px', // Space between different chats
                                                    }
                                                }
                                            >
                                                {message.content === '' ? (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                        }}                                      
                                                    >
                                                        {renderLLMNewLines(message.content)}
                                                    </Typography>
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                        <ListItem ref={bottomOfChatRef}></ListItem>
                    </div>

                    {/* <div
                        style={{
                            marginBottom: '5px',
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr', // Two columns with equal width
                            maxHeight: '70px',
                            overflowY: 'auto',
                        }}
                    >
                    </div> */}

                    <div
                        style={{
                            marginBottom: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: '80px',
                            overflowY: 'auto',
                        }}
                    >
                        {conversationStarters.map(starter => (
                            <Button
                                key={starter.id}
                                variant="outlined"
                                onClick={() => handleConversationStarter(starter.text)}
                                style={{
                                    marginTop: '2px',
                                    marginRight: '5px',
                                    color: theme.palette.primary.main,
                                    borderColor: theme.palette.primary.main,
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                    lineHeight: '1.3',
                                }}
                            >
                                {starter.text}
                            </Button>
                        ))}
                    </div>

                    <Grid
                        container
                        direction="row"
                    >
                        <Grid
                            item
                            xs={9.5}
                        >
                            <TextField
                                label="Type a message"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={2}
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                style={{
                                    fontSize: "0.9rem",
                                    marginTop: '3px',
                                    marginBottom: '5px',
                                }}
                                onKeyDown={handleKeyEnter}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={2.5}
                        >
                            <div
                                style={{ 
                                    height: '100%',
                                    paddingLeft: '7px',
                                    paddingBottom: '5px'
                                }}
                            >
                                {isServerWriting ? (
                                    <LinearProgress style={{ height: '100%' }} />
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSendMessage}
                                        style={{width: '100%', height: '100%', fontWeight: 'bold'}}
                                    >
                                        Send
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}
