import { Grid, Box, Typography, Container } from '@mui/material';
import { logEvent } from '../networking';
import { useLocalStorage } from '@uidotdev/usehooks';
import { baseExperimentStatus, Pages, getActiveElement, EmailTypes } from '../Types';
import { BACKEND_ADDRESS } from '../App';
import TPHeader from '../components/llmtraining/TrainingPage/Header';
import TPFooter from '../components/llmtraining/TrainingPage/Footer';
import TPEmailClues from '../components/llmtraining/TrainingPage/EmailClues';
import TPLLMChat from '../components/llmtraining/TrainingPage/LLMChat';
import { Subtitle, Title } from '../components/base/typography';


export default function TrainingPage() {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);
    const [openEmail, setOpenEmail] = useLocalStorage("openEmail", null);

    const [returnedFromTrainingPage, setReturnedFromTrainingPage] = useLocalStorage("returnedFromTrainingPage", false);

    const handleReturnToMailboxViaTopButton = () => {
        logEvent(participant._id, "frontend___trainingPage_gotoMailClient_via_TopButton", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setReturnedFromTrainingPage(true);
        setExperiment({ ...experiment, page: Pages.MAILBOX })
    };

    const handleReturnToMailboxViaBottomButton = () => {
        logEvent(participant._id, "frontend___trainingPage_gotoMailClient_via_BottomButton", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setReturnedFromTrainingPage(true);
        setExperiment({ ...experiment, page: Pages.MAILBOX })
    };

    return (
        <Box>
            <Box
                sx={{
                    height: '10.5vh',
                    maxHeight: '10.5vh',
                    overflow: 'auto' 
                }}
            >
                <TPHeader
                    callback={handleReturnToMailboxViaTopButton}
                />
            </Box>

            <Container
                sx={{
                    display: 'grid',
                    gridTemplateColumns: (
                        (
                            getActiveElement(participant.group, "Training page with LLM chat")
                        ) ?
                        '60% 40%' : '100% 0%'
                    ),
                    height: '87.5vh',
                    maxHeight: '87.5vh',
                    overflow: 'auto'
                }}
            >
                <Grid
                    sx={{
                        height: '85vh',
                        maxHeight: '85vh',
                        overflow: 'auto',
                    }}
                >
                    <TPEmailClues
                        participant={participant}
                        openEmail={openEmail}
                        callback={handleReturnToMailboxViaBottomButton}
                    />
                </Grid>
                {
                    getActiveElement(participant.group, "Training page with LLM chat") &&
                    <Grid
                        sx={{
                            height: '85vh',
                            maxHeight: '85vh',
                            overflow: 'auto',
                        }}
                    >
                        <TPLLMChat
                            participant={participant}
                            openEmail={openEmail}
                            backend_address={BACKEND_ADDRESS}
                        />
                    </Grid>
                }
            </Container>


            <Grid
                container
                sx={{
                    height: '2vh',
                    maxHeight: '2vh',
                    overflow: 'auto' 
                }}
            >
                <TPFooter/>
            </Grid>

        </Box>

    );
}