import { Grid, Typography, Avatar } from "@mui/material"
import { useMemo } from "react";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import EmailRecipient from "./EmailRecipient";
import dompurify from "dompurify"


export default function EmailHeader({email, propic='', ...props}) {
    const dompurifyConfig = {
        ALLOWED_ATTR: ['class', 'className'],
    };

    propic = propic || "/broken.png";

    const emailSubject = useMemo(() => {
        return dompurify.sanitize(email.subject, dompurifyConfig)
    }, [email]);

    const emailSenderAddress = useMemo(() => {
        return dompurify.sanitize(email.sender.address, dompurifyConfig);
    }, [email]);

    return (
        <Grid container {...props}>

            <Grid item xs={12} sx={{paddingBottom: 4}}>
                <Typography variant='h5'>
                    <span
                        dangerouslySetInnerHTML={{ __html: emailSubject }}
                    />
                </Typography>
            </Grid>

            <Grid item xs={1}>
            <Avatar
                sx={{width: 56, height: 56}}
                src={propic}
            />
            </Grid>
            <Grid item container xs={11} sx={{ textAlign: "left"}} justifyContent="center" flexDirection="column">
                <Typography color="primary" marginBottom="0.1rem">
                    {email.sender.display_name} &lt;{
                        <span
                            dangerouslySetInnerHTML={{ __html: emailSenderAddress }}
                        />
                    }&gt;
                </Typography>
                <Typography sx={{fontSize: "0.8rem"}} marginBottom="0.1rem" color="text.secondary">
                    Today, {email.timestamp}
                </Typography>
                <EmailRecipient email={email} />
            </Grid>

        </Grid>


    )


}