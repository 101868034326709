import { Container, Typography, Box } from "@mui/material";
import { Subtitle, Title } from "../components/base/typography";

export default function ConsentFull() {

    return (
        <Container>
            <Title>
                Full Consent Form - Large Language Models for Phishing Training and Education
            </Title>

            <Typography>
                We are a team of researchers from <a href='https://ethz.ch'>ETH Zurich</a>, a public research university
                in Switzerland, investigating how people interact with email clients and online safety.
            </Typography>

            <Typography pb={2}>
                We would like to ask you if you are willing to participate in our research project. Your participation
                is voluntary. Please read the text below carefully.
            </Typography>

            <Typography>
                You can contact the research team by writing to Daniele Lain, ETH Zurich&nbsp;
                (<a href='mailto:daniele.lain@inf.ethz.ch'>daniele.lain@inf.ethz.ch</a>).
            </Typography>

            <Typography>
                The Data Protection Officer of ETH Zurich is Tomislav Mitar&nbsp;
                (<a href='mailto:tomislav.mitar@sl.ethz.ch'>tomislav.mitar@sl.ethz.ch</a>).
            </Typography>


            <Subtitle>
                What is being investigated and how?
            </Subtitle>

            <Typography>
                We are designing novel ways to help users detect fraudulent emails using Large Language Models - that
                you might know as “chatbots” such as the popular ChatGPT and Google Gemini. We want to evaluate how
                users interact with the LLMs and what their experience and opinion of the performance of this novel
                technology are. Our study compares different existing techniques and our novel mechanisms. We are
                investigating this with a role-play study: you will be assigned a fictitious identity of an employee
                in a company. You will be asked to manage their business emails in a short time.
            </Typography>


            <Subtitle>
                Who can participate?
            </Subtitle>

            <Typography>
                Internet users aged 18+ who own and regularly use a personal or business email address
                are eligible for participation in this study. Users must speak fluent English and have a task approval
                rate of at least 95% on the platform.
            </Typography>


            <Subtitle>
                What am I supposed to do as a participant?
            </Subtitle>

            <Typography>
                As a participant, you will be asked to fill a questionnaire regarding your email habits at the start of
                the study. Following a brief tutorial, you will be instructed to pretend to be a fictitious employee of
                a company. You will then be displayed the mailbox of the employee. You have a fixed amount of time to
                work through as many emails in your inbox as possible according to the instructions you will be given.
                After working on the inbox, you will be asked to fill a second questionnaire regarding your perception
                of the user interfaces you will have tested.
            </Typography>


            <Subtitle>
                What are my rights during participation?
            </Subtitle>

            <Typography>
                Your participation in this study is voluntary. You may withdraw your participation at any time without
                specifying reasons and without any disadvantages by clicking on the clearly marked links
                on the study webpages.
            </Typography>


            <Subtitle>
                What risks and benefits can I expect?
            </Subtitle>

            <Typography>
                We do not collect any personal data during the execution of the experiment. Your data is safe: we ask
                you to pretend to be an employee of a fictitious company, and you will manage simulated emails for
                this employee in a protected environment. Any URL you might click on during the study will not lead
                you to any dangerous content.
            </Typography>


            <Subtitle>
                Will I be compensated for participating?
            </Subtitle>

            <Typography>
                You will be paid through the experimental platform for your participation. At the end of the study, or
                whenever you decide to interrupt your participation, you will receive a single-use code that confirms
                your participation in the study; you will be asked to enter this code back in the website to receive
                your remuneration.
            </Typography>


            <Subtitle>
                What data is collected from me and how is it used?
            </Subtitle>

            <Typography pb={2}>
                No Personally Identifiable Information (PII) is collected from you during the study. Certain
                questionnaire items are about attributes that might be perceived as sensitive (e.g., gender, age
                range). All questionnaire questions are optional; users can prefer not to answer to any of them.
            </Typography>

            <Typography pb={2}>
                During the experiment execution, we will measure the following data from your interaction with the
                simulated mailbox and emails:
                <ul>
                    <li>
                        Performance on the task (how many emails are managed, how quickly, and how they were handled).
                    </li>
                    <li>
                        Spent time per task: how long it took you to read and manage each email, how much time you
                        spend on different study pages, and how much time you are spending on our study compared to,
                        e.g., multitasking with other Internet activities.
                    </li>
                    <li>
                        Should you be asked to test any chatbot interface, we will record your full conversations with
                        the AI (all the chat messages you sent to it and all of its replies).
                    </li>
                </ul>
            </Typography>

            <Typography>
                All collected data will be stored securely in a server of the System Security group, ETH Zurich, in a
                pseudonymized form with your platform id. No data will be published raw: we will only report aggregated
                statistics in scientific publications following this study. Nothing from the recorded data can be used
                to draw any conclusion about any individual participant.
            </Typography>

            <Typography pb={2}>
                Quotes or excerpts from your interaction with the LLM may be reported in scientific publications or
                other materials, given that they do not contain any personal data or allow for your identification.
            </Typography>

            <Typography>
                Members of the ETH Zurich Ethics Commission may access the original data for examination purposes.
                Strict confidentiality will be observed at any time.
            </Typography>


            <Subtitle>
                What are my rights to my personal data?
            </Subtitle>

            <Typography>
                Before the irrevocable anonymisation of the collected data, you can request information about the
                personal data collected from you at any time and without giving reasons. You can also request that it
                be rectified, handed over to you, barred for processing or erased. To do so, please contact the person
                indicated above.
            </Typography>


            <Subtitle>
                Who reviewed this study?
            </Subtitle>

            <Typography>
                This study was examined by the ETH Zurich Ethics Commission as proposal EK-2024-N-104.
            </Typography>


            <Subtitle>
                Complaints office
            </Subtitle>

            <Typography>
                The secretariat of the ETH Zurich Ethics Committee is available to help you with complaints in
                connection with your participation.
            </Typography>

            <Typography>
                Contact:&nbsp;
                <a href='mailto:ethics@sl.ethz.ch'>ethics@sl.ethz.ch</a>&nbsp;
                or&nbsp;
                <a href='tel:0041446328572'>0041 44 632 85 72</a>.
            </Typography>
            
            <Box py={2}></Box>

        </Container>
    );
}