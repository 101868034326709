import { ListItem, ListItemButton, Divider, Stack, alpha } from '@mui/material'
import { MailPreview, MailSender, MailSubject, MailTimestamp } from '../base/typography'
import Grid from '@mui/material/Unstable_Grid2';
import { theme } from '../../theme';
import { useMemo } from 'react';

export default function EmailsListItem({ email, clickHandler, ...props }) {

    const preview = useMemo(() => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(email.body, 'text/html');
        let previewText = htmlDoc.body.innerText;
        previewText = previewText.replaceAll(/\n+|\t+|\s+/g, ' ');
        previewText = previewText.replaceAll(/\s+/g, ' ');
        return previewText;
    }, [email.body]);

    const previewSubject = useMemo(() => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(email.subject, 'text/html');
        let previewText = htmlDoc.body.innerText;
        previewText = previewText.replaceAll(/\n+|\t+|\s+/g, ' ');
        previewText = previewText.replaceAll(/\s+/g, ' ');
        return previewText;
    }, [email.subject]);

    return (
        <>
            <ListItem disablePadding sx={{
                backgroundColor: email.selected ? "#C7E0F4" : "transparent",
            }}>
                <ListItemButton onClick={(event) => clickHandler(event, email)} >
                    <Grid container>
                        <Grid xs={12}>
                            <MailSender
                                unread={!email.read}
                            >
                                {email.sender.display_name || email.sender.address}
                            </MailSender>
                        </Grid>
                        <Grid xs={10} >
                            <MailSubject
                                unread={!email.read}
                            >
                                {previewSubject}
                            </MailSubject>
                        </Grid>
                        <Grid xs={2} >
                            <MailTimestamp
                                unread={!email.read}
                            >
                                {email.timestamp}
                            </MailTimestamp>
                        </Grid>
                        <Grid xs={10}>
                            <MailPreview
                                unread={!email.read}
                            >
                                {preview.substring(0, 80)}
                                {preview.length > 80 ? "..." : ''}
                            </MailPreview>
                        </Grid>
                    </Grid>
                </ListItemButton>

            </ListItem>
            <Divider variant='middle' />
        </>
    )

}
