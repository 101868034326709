import {Divider, Grid } from '@mui/material'
import EmailHeader from '../email/EmailHeader'
import ExternalWarning from '../email/ExternalWarning';
import EmailBody from '../email/EmailBody';

export default function EmailViewer({email, onShadowDomCreation=null, ...props}) {


    return (
        <Grid container sx={{ alignItems: "left"}}>

            <EmailHeader email={email} marginBottom="0.8rem" />

            <Grid item xs={1}></Grid>
            <Grid item xs={11}>

                <Grid item xs={12}>
                    {   
                        email.extra_info.external_warning ? 
                            <ExternalWarning /> : 
                            <Divider sx={{my: "2rem"}} />
                    }
                </Grid>

                <Grid item xs={12}>
                    <EmailBody email={email} onShadowDomCreation={onShadowDomCreation} {...props} />
                </Grid>

            </Grid>
        </Grid>
    );
}
