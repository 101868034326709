import { Stack, Divider } from '@mui/material'

export default function MailboxButtons({...props}) {

    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            divider={<Divider variant='middle' orientation="vertical" flexItem />}
            sx={{width: "100%"}}
        >
            {props.children}
        </Stack>
    )
}