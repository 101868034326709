import { createTheme } from '@mui/material/styles';


export const theme = createTheme({
    typography: {
      fontFamily: ["DejaVu", "Arial", "sans-serif"],
      monospace: {
        fontFamily: ["Monospace"]
      },
      button: {
        textTransform: 'none',
        fontFamily: ["DejaVu", "Arial", "sans-serif"]
      },
      outlookui: {
        fontFamily: ["Calibri", "sans-serif"]
      },
      footer: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: 60,
        textAlign: 'center'
      }
    },
    palette: {
      primary: {
        main: '#215caf',
      },
      secondary: {
        main: '#222',
      },
    },
    llmChatPalette: {
      bot: '#e0e0e0',
      user: '#cce1ff',
    },
    cluesPalette: {
      primary: '#215caf',
      secondary: '#cce1ff',
    },
  });