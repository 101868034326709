import { Grid, Box } from '@mui/material';
import { logEvent } from '../networking';
import { useLocalStorage } from '@uidotdev/usehooks';
import { baseExperimentStatus, Pages, getActiveElement } from '../Types';
import { BACKEND_ADDRESS } from '../App';
import DPHeader from '../components/llmtraining/DebuggingPage/Header';
import TPFooter from '../components/llmtraining/TrainingPage/Footer';
import DPLLMChat from '../components/llmtraining/DebuggingPage/LLMChat';


export default function DebuggingPage() {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);
    const [openEmail, setOpenEmail] = useLocalStorage("openEmail", null);

    const handleReturnToMailbox = () => {
        logEvent(participant._id, "frontend___debuggingPage_gotoMailClient", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
            }
        );
        setExperiment({ ...experiment, page: Pages.MAILBOX })
    };

    return (
        <Box className="mailbox-container">
            <Box
                sx={{
                    height: '10.5vh',
                    maxHeight: '10.5vh',
                    overflow: 'auto' 
                }}
            >
                <DPHeader
                    callback={handleReturnToMailbox}
                />
            </Box>


            <Grid
                container
                direction="column"
                sx={{
                    height: '87.5vh',
                    maxHeight: '87.5vh',
                    overflow: 'auto'
                }}
            >
                <DPLLMChat
                    participant={participant}
                    openEmail={openEmail}
                    backend_address={BACKEND_ADDRESS}
                />
            </Grid>


            <Grid
                container
                sx={{
                    height: '2vh',
                    maxHeight: '2vh',
                    overflow: 'auto' 
                }}
            >
                <TPFooter/>
            </Grid>

        </Box>

    );
}