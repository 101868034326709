import { React } from "react";
import { styled } from '@mui/material/styles';
import { Typography, Stack, ButtonBase } from "@mui/material";
import { ErrorOutline } from '@mui/icons-material';

const IconButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      backgroundColor: "#EEE"
    },
    '&:hover, &.Mui-disabled': {
    },
    '&.Mui-disabled': {
        opacity: 0.5
    }
  }));

export function MailboxButton({
                                        image=<ErrorOutline />,
                                        callback=() => '',
                                        width="128px",
                                        height="128px",
                                        padding="16px",
                                        spacing=2,
                                        fontSize="20px",
                                        ...props
                                }) {

    const buttonImage = image || <ErrorOutline />

    return (
        <IconButton 
            style={{
                width: width,
                height: height,
                padding: padding
            }} 
            onClick={callback}
            disabled={props.disabled}
            {...props}
        >
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={spacing}
            >
                {buttonImage}
                <Typography style={{ fontSize: fontSize }}>{props.children}</Typography>

            </Stack>
        </IconButton>
    )
}

export function MailboxButtonSmall({
                                        image=<ErrorOutline />,
                                        callback=() => '',
                                        width="100px",
                                        height="64px",
                                        padding="2px",
                                        spacing=0,
                                        fontSize="12px",
                                        ...props
                                    }) {
    return (
        <MailboxButton 
            image={image}
            callback={callback}
            width={width}
            height={height}
            padding={padding}
            spacing={spacing}
            fontSize={fontSize}
            {...props}
        />
    )
}
