export const ExperimentProgress = {
    NOT_STARTED: 0,
    STARTED: 1,
    OVER: 2,
    LEFT_EARLY: 3,
    TIMEOUT: 4,
};

export const Pages = {
    CONSENT_SHORT: 0,
    CONSENT_LONG: 1,
    PRE_QUESTIONNAIRE: 2,
    BACKGROUND: 3,
    MAILBOX: 4,
    TRAINING_PAGE: 5,
    TRAINING_PAGE_POST: 6,
    DEBUGGING_PAGE: 7,
    DEBRIEFING: 8,
    POST_QUESTIONNAIRE: 9,
    REWARD: 10
};

export const ExperimentModes = {
    PRODUCTION: 0,
    DEBUGGING: 1,
};


export const baseExperimentStatus = {
    "experimentMode":   ExperimentModes.PRODUCTION,          // Needs to be set to PRODUCTION for the real experiment
    "page":             Pages.CONSENT_SHORT,
    "status":           ExperimentProgress.NOT_STARTED,
    "mailboxOnboarded": false,
};

export const userGroups = [
    "control",
    "passive_warnings___no_llm",
    "passive_warnings___rephrase",
    "passive_warnings___chat",
    "interactive_warnings___no_llm",
    "interactive_warnings___rephrase",
    "interactive_warnings___chat",
    "training_page___no_llm",
    "training_page___rephrase",
    "training_page___chat"
];

const userGroupsToNumbers = {
    "control":                          0,
    "passive_warnings___no_llm":        1,
    "passive_warnings___rephrase":      2,
    "passive_warnings___chat":          3,
    "interactive_warnings___no_llm":    4,
    "interactive_warnings___rephrase":  5,
    "interactive_warnings___chat":      6,
    "training_page___no_llm":           7,
    "training_page___rephrase":         8,
    "training_page___chat":             9
};

const listUIComponents = {
    "Right-hand side clues widget":          0,
    "Right-hand side details":               1,
    "Right-hand side LLM chat":              2,
    "Overlay, basic (blocking warnings)":    3,
    "Overlay, details":                      4,
    "Training page":                         5,
    "Training page with details buttons":    6,
    "Training page with LLM chat":           7,
};

// Abbreviations
//     RHC -> Right-hand side clues widget
//     RHD -> Right-hand side details
//     RHL -> Right-hand side LLM chat
//
//     OB  -> Overlay, basic (blocking warnings)
//     OD  -> Overlay, details
//
//     TP  -> Training page
//     TPD -> Training page with details buttons
//     TPL -> Training page with LLM chat
//
// ---------------------------------------------------------------------------------------------
// ----------------------------------------RHC--RHD--RHL-- OB-- OD-- TP--TPD--TPL---------------
// "control"-----------------------------##   ##   ##   ##   ##   ##   ##   ##   ###############
// "passive_warnings___no_llm"-----------## + ##   ##   ##   ##   ##   ##   ##   ###############
// "passive_warnings___rephrase"---------## + ## + ##   ##   ##   ##   ##   ##   ###############
// "passive_warnings___chat"-------------## + ##   ## + ##   ##   ##   ##   ##   ###############
// "interactive_warnings___no_llm"-------## + ##   ##   ## + ##   ##   ##   ##   ###############
// "interactive_warnings___rephrase"-----## + ## + ##   ## + ## + ##   ##   ##   ###############
// "interactive_warnings___chat"---------## + ##   ## + ## + ##   ##   ##   ##   ###############
// "training_page___no_llm"--------------##   ##   ##   ##   ##   ## + ##   ##   ###############
// "training_page___rephrase"------------##   ##   ##   ##   ##   ## + ## + ##   ###############
// "training_page___chat"----------------##   ##   ##   ##   ##   ## + ##   ## + ###############
// ---------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------

const userGroupPlusUIComponentToBoolean = [
// ----RHC----RHD----RHL---- OB---- OD---- TP----TPD----TPL-------------------------------------
    [    0,     0,     0,     0,     0,     0,     0,     0],  // "control"
    [  100,     0,     0,     0,     0,     0,     0,     0],  // "passive_warnings___no_llm"
    [  100,   100,     0,     0,     0,     0,     0,     0],  // "passive_warnings___rephrase"
    [  100,     0,   100,     0,     0,     0,     0,     0],  // "passive_warnings___chat"
    [  100,     0,     0,   100,     0,     0,     0,     0],  // "interactive_warnings___no_llm"
    [  100,   100,     0,   100,   100,     0,     0,     0],  // "interactive_warnings___rephrase"
    [  100,     0,   100,   100,     0,     0,     0,     0],  // "interactive_warnings___chat"
    [    0,     0,     0,     0,     0,   100,     0,     0],  // "training_page___no_llm"
    [    0,     0,     0,     0,     0,   100,   100,     0],  // "training_page___rephrase"
    [    0,     0,     0,     0,     0,   100,     0,   100],  // "training_page___chat"
];

export function getActiveElement(userGroup, uiComponent) {
    const userGroupNumber = userGroupsToNumbers[userGroup];
    const uiComponentNumber = listUIComponents[uiComponent];

    if (userGroupNumber === undefined) {
        throw new Error(`Invalid user group: ${userGroup}`);
    }

    if (uiComponentNumber === undefined) {
        throw new Error(`Invalid UI component: ${uiComponent}`);
    }

    const resultN = userGroupPlusUIComponentToBoolean[userGroupNumber][uiComponentNumber];

    if (resultN === undefined) {
        throw new Error(`Undefined userGroupPlusUIComponentToBoolean result for ${userGroup} and ${uiComponent}`);
    }

    if (resultN != 0 && resultN != 100) {
        throw new Error(`Invalid translation. Must be 0 or 100, found: ${resultN}`);
    }

    if (resultN === 100) return true;
    return false;
}

// Must be the same as "backend/utils/datastructures.py"
export const EmailTypes = {
    LEGITIMATE: 0,
    PHISHING: 1,
};
