import { useState } from 'react';
import { Button, Box, Typography, Container, Paper, Card, CardMedia } from '@mui/material'
import FormControl from '@mui/material/FormControl';
import { BACKEND_ADDRESS } from '../App';
import { Subtitle, Title } from '../components/base/typography';
import QuestionnaireGroup from '../components/framework/Questionnaire/QuestionnaireGroup';
import QuestionnaireLikert from '../components/framework/Questionnaire/QuestionnaireLikert';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Pages } from '../Types';


export default function PostQ() {

    const [feedbackFormValues, setFeedbackFormValues] = useState({});
    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", {});

    const handleChange = (event) => {
        
        setFeedbackFormValues({
            ...feedbackFormValues,
            [event.target.name]: event.target.value,
          });
          
    };

    const handleClickConfirm = () => {
        
        fetch(BACKEND_ADDRESS + "/answers",
            {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    'participant_id': participant._id,
                    'form_name': 'feedback',
                    'form_data': feedbackFormValues
                })
            }
        )
            .then(async (res) => {
                let result = await res.json();
                setExperiment({ ...experiment, page: Pages.REWARD })
            })
            .catch((err) => console.log(err));
  
        
    };


    // Common Questions

    let commonQs = [
        [
            "common___scam_detection_by_reading",
            "I felt confident in detecting the scam emails by reading them."
        ],
        [
            "common___scam_detection_difficulty",
            "I felt the scam emails were difficult to detect."
        ]
    ];


    // Passive Warnings Questions

    let warnings___AllQs = [
        [
            "warnings_all___very_useful",
            "I found the warnings very useful to spot the phishing emails."
        ],
        [
            "warnings_all___too_many",
            "The warnings were too many or too superfluous."
        ],
        [
            "warnings_all___learned_a_lot",
            "I feel I learned a lot, thanks to the warnings."
        ]
    ];


    // Interactive Warnings Questions

    let warningsInteractive___AllQs = [
        [
            "warningsInteractive_all___were_useful",
            "I found it useful to see the warnings next to their suspicious elements."
        ],
        [
            "warningsInteractive_all___in_the_way_of_doing_things",
            "The warnings next to their email elements were in the way of doing my tasks."
        ]
    ];


    // Training page questions for all cases

    let training___AllQs = [
        [
            "training_all___was_helpful",
            "The training page helped me correctly identify the phishing emails."
        ],
        [
            "training_all___was_too_long",
            "The training page was too long."
        ],
        [
            "training_all___contained_unnecessary_information",
            "The training page contained unnecessary information."
        ]
    ];


    // Rephrase Questions

    let warnings___RephraseQs = [
        [
            "warnings_rephrase___button_useful",
            "I found the button for further explanations useful."
        ],
        [
            "warnings_rephrase___was_confusing",
            "The further information was confusing."
        ],
        [
            "warnings_rephrase___unnatural_writing_style",
            "The writing style of the further information was unnatural."
        ],
        [
            "warnings_rephrase___further_information_was_useful",
            "I found the further information to be useful to spot phishing."
        ],
        [
            "warnings_rephrase___did_not_learn_a_lot",
            "I did not learn a lot from the further explanations."
        ]
    ];

    let training___RephraseQs = [
        [
            "training_rephrase___button_useful",
            "I found the button for further explanations useful."
        ],
        [
            "training_rephrase___was_confusing",
            "The further information was confusing."
        ],
        [
            "training_rephrase___worse_than_training",
            "I found the further information to be less informative than the training content."
        ],
        [
            "training_rephrase___unnatural_writing_style",
            "The writing style of the further information was unnatural."
        ],
        [
            "training_rephrase___further_information_was_useful",
            "I found the further information to be useful to spot phishing."
        ],
        [
            "training_rephrase___did_not_learn_a_lot",
            "I did not learn a lot from the further explanations."
        ]
    ];


    // LLM Chat Questions

    let warnings___ChatQs = [
        [
            "warnings_chat___was_useful",
            "The chatbot was useful."
        ],
        [
            "warnings_chat___typing_time_consuming",
            "Typing my questions to the chatbot was too time-consuming."
        ],
        [
            "warnings_chat___trust",
            "I trusted the answers of the chatbot."
        ],
        [
            "warnings_chat___no_useful_information",
            "The chatbot did not give me useful information."
        ],
        [
            "warnings_chat___gave_precise_answers",
            "The chatbot gave me precise answers to my questions."
        ],
        [
            "warnings_chat___like_starters",
            "I liked the provided conversation prompts for the chat widget."
        ],
        [
            "warnings_chat___tone_irritating",
            "I found the tone of the chatbot irritating."
        ],
        [
            "warnings_chat___learned_a_lot",
            "I feel I learned a lot from the chatbot answers."
        ]
    ];

    let training___ChatQs = [
        [
            "training_chat___was_useful",
            "The chatbot was useful."
        ],
        [
            "training_chat___typing_time_consuming",
            "Typing my questions to the chatbot was too time-consuming."
        ],
        [
            "training_chat___trust",
            "I trusted the answers of the chatbot."
        ],
        [
            "training_chat___no_useful_information",
            "The chatbot did not give me useful information."
        ],
        [
            "training_chat___gave_precise_answers",
            "The chatbot gave me precise answers to my questions."
        ],
        [
            "training_chat___like_starters",
            "I liked the provided conversation prompts for the chat widget."
        ],
        [
            "training_chat___tone_irritating",
            "I found the tone of the chatbot irritating."
        ],
        [
            "training_chat___chat_less_useful_than_training",
            "The answers given by the chatbot were less useful than the training page content when detecting phishing emails."
        ],
        [
            "training_chat___learned_a_lot",
            "I feel I learned a lot from the chatbot answers."
        ]
    ];

    function renderQs(questionsList) {
        return (
            <QuestionnaireGroup least="Strongly disagree" most="Strongly agree">
                {
                    questionsList.map((items, idx) => (
                        <QuestionnaireLikert key={idx} label={items[1]} name={items[0]} onChange={handleChange} />
                    ))
                }
            </QuestionnaireGroup>
        )
    }


    const DEBUGGING_SHOW_ALL = "False";


    return (
        <Container>
            <Title>
                Post-Experiment Questionnaire
            </Title>

            <Typography>
                We will ask you some final questions about the study. Please fill the following questionnaire to complete
                your task.
            </Typography>
            <Typography pb={4} pt={2}>
                In the following, reply by selecting a value from 1 ("Strongly disagree") to 5 ("Strongly agree").
            </Typography>


            {/* Common Questions */}

            <FormControl>
                {renderQs(commonQs)}
            </FormControl>


            {/* Passive or Interactive Warnings Questions */}

            {
                (
                    participant.group === "passive_warnings___no_llm" ||
                    participant.group === "passive_warnings___rephrase" ||
                    participant.group === "passive_warnings___chat" ||
                    participant.group === "interactive_warnings___no_llm" ||
                    participant.group === "interactive_warnings___rephrase" ||
                    participant.group === "interactive_warnings___chat" ||
                    DEBUGGING_SHOW_ALL === "True"
                ) && 
                <>
                    <Subtitle>
                        Suspicious Email Indicators (Passive Warnings)
                    </Subtitle>

                    <Typography>
                        The following questions are about the <b>Suspicious Email Indicators</b> widget.
                        It provided a list of the suspicious clues present in the email.
                    </Typography>

                    <FormControl>
                        <Paper 
                            elevation={10}
                            style={{
                                marginTop: "32px"
                            }}
                        >
                            <Card>
                                <CardMedia
                                    component="img"
                                    image="images/postq_gifs/1_warnings.gif"
                                    sx={{
                                        maxWidth: "800px",
                                        margin: "16px auto"
                                    }}
                                />
                            </Card>
                        </Paper>

                        {renderQs(warnings___AllQs)}
                    </FormControl>
                </>
            }


            {/* Interactive Warnings Questions */}

            {
                (
                    participant.group === "interactive_warnings___no_llm" ||
                    participant.group === "interactive_warnings___rephrase" ||
                    participant.group === "interactive_warnings___chat" ||
                    DEBUGGING_SHOW_ALL === "True"
                ) &&
                <>
                    <Subtitle>
                        "Show" Button (Interactive Warnings)
                    </Subtitle>

                    <Typography>
                        The following questions are about the <b>"Show"</b> button, which was part of
                        the  <b>Suspicious Email Indicators</b> widget.
                        <br/>
                        It showed the exact place where the clues were present in the email.
                    </Typography>

                    <FormControl>
                        <Paper 
                            elevation={10}
                            style={{
                                marginTop: "32px"
                            }}
                        >
                            <Card>
                                <CardMedia
                                    component="img"
                                    image="images/postq_gifs/2_warningsInteractive.gif"
                                    sx={{
                                        maxWidth: "800px",
                                        margin: "16px auto"
                                    }}
                                />
                            </Card>
                        </Paper>

                        {renderQs(warningsInteractive___AllQs)}
                    </FormControl>
                </>
            }


            {/* Training Page Questions */}

            {
                (
                    participant.group === "training_page___no_llm" ||
                    participant.group === "training_page___rephrase" ||
                    participant.group === "training_page___chat" ||
                    DEBUGGING_SHOW_ALL === "True"
                ) &&
                <>
                    <Subtitle>
                        Training Page
                    </Subtitle>

                    <Typography>
                        The following questions are about the <b>Training Page</b>.
                    </Typography>

                    <FormControl>
                        <Paper 
                            elevation={10}
                            style={{
                                marginTop: "32px"
                            }}
                        >
                            <Card>
                                <CardMedia
                                    component="img"
                                    image="images/postq_gifs/3_trainingPage.gif"
                                    sx={{
                                        maxWidth: "800px",
                                        margin: "16px auto"
                                    }}
                                />
                            </Card>
                        </Paper>

                        {renderQs(training___AllQs)}
                    </FormControl>
                </>
            }


            {/* Rephrasing Questions */}

            {
                (
                    participant.group === "passive_warnings___rephrase" ||
                    participant.group === "interactive_warnings___rephrase" ||
                    DEBUGGING_SHOW_ALL === "True"
                ) &&
                <>
                    <Subtitle>
                        "Details" Buttons (LLM Rephrasing)
                    </Subtitle>

                    <Typography>
                        The following questions cover the <b>"Details"</b> buttons of
                        the <b>Suspicious Email Indicators</b> widget.
                    </Typography>

                    <FormControl>
                        <Paper 
                            elevation={10}
                            style={{
                                marginTop: "32px"
                            }}
                        >
                            <Card>
                                <CardMedia
                                    component="img"
                                    image="images/postq_gifs/4_details.gif"
                                    sx={{
                                        maxWidth: "800px",
                                        margin: "16px auto"
                                    }}
                                />
                            </Card>
                        </Paper>

                        {renderQs(warnings___RephraseQs)}
                    </FormControl>
                </>
            }

            {
                (
                    participant.group === "training_page___rephrase" ||
                    DEBUGGING_SHOW_ALL === "True"
                ) &&
                <>
                    <Subtitle>
                        Training Page: "Details" Buttons (LLM Rephrasing)
                    </Subtitle>

                    <Typography>
                        The following questions cover the <b>"Details"</b> buttons, which were present on
                        the <b>Training Page</b>.
                    </Typography>

                    <FormControl>
                        <Paper 
                            elevation={10}
                            style={{
                                marginTop: "32px"
                            }}
                        >
                            <Card>
                                <CardMedia
                                    component="img"
                                    image="images/postq_gifs/5_trainingPageDetails.gif"
                                    sx={{
                                        maxWidth: "800px",
                                        margin: "16px auto"
                                    }}
                                />
                            </Card>
                        </Paper>

                        {renderQs(training___RephraseQs)}
                    </FormControl>
                </>
            }


            {/* LLM Chat Questions */}

            {
                (
                    participant.group === "passive_warnings___chat" ||
                    participant.group === "interactive_warnings___chat" ||
                    DEBUGGING_SHOW_ALL === "True"
                ) &&
                <>
                    <Subtitle>
                        LLM Chat
                    </Subtitle>

                    <Typography>
                        The following questions cover the <b>LLM Chat</b>.
                    </Typography>

                    <FormControl>
                        <Paper 
                            elevation={10}
                            style={{
                                marginTop: "32px"
                            }}
                        >
                            <Card>
                                <CardMedia
                                    component="img"
                                    image="images/postq_gifs/6_llmChat.gif"
                                    sx={{
                                        maxWidth: "800px",
                                        margin: "16px auto"
                                    }}
                                />
                            </Card>
                        </Paper>

                        {renderQs(warnings___ChatQs)}
                    </FormControl>
                </>
            }

            {
                (
                    participant.group === "training_page___chat" ||
                    DEBUGGING_SHOW_ALL === "True"
                ) &&
                <>
                    <Subtitle>
                        Training Page: LLM Chat
                    </Subtitle>

                    <Typography>
                        The following questions cover the <b>LLM Chat</b>, which was present on
                        the <b>Training Page</b>.
                    </Typography>

                    <FormControl>
                        <Paper 
                            elevation={10}
                            style={{
                                marginTop: "32px"
                            }}
                        >
                            <Card>
                                <CardMedia
                                    component="img"
                                    image="images/postq_gifs/7_trainingPageLLMChat.gif"
                                    sx={{
                                        maxWidth: "800px",
                                        margin: "16px auto"
                                    }}
                                />
                            </Card>
                        </Paper>

                        {renderQs(training___ChatQs)}
                    </FormControl>
                </>
            }


            <Box py={2}></Box> 
            <Button variant="contained" className="Button" onClick={(event) => handleClickConfirm()}>
                Continue
            </Button>

            <Box py={2}></Box>
        
        </Container>
    );
}
