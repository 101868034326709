import React, { useEffect, useState, useRef } from 'react';
import { Typography, Container, Paper, TextField, Button, ListItem, Grid } from '@mui/material'
import { CircularProgress, LinearProgress, Avatar } from '@mui/material'
import { theme } from '../../../theme';
import { logEvent } from '../../../networking';

// PartialServerMessage from the server
// which is received token by token
export class PartialServerMessage {
    constructor(message, isEnd){
        this.message = message;
        this.isEnd = isEnd;
    }
}

export default function DPLLMChat({participant, openEmail, backend_address, ...props}) {
    
    const KEY_CODE__ENTER = 13;

    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    // We cannot send messages while the server is replying
    const [isServerWriting, setIsServerWriting] = useState(false);
    const webSocket = useRef(null);
    const bottomOfChatRef = useRef(null);

    const llmImageIconPath = `images/llmIcons/robot.png`;

    const openWebSocket = () => {
        // WebSocket
        logEvent(participant._id, "frontend___debuggingPage_llmChat_websocket_opening", {
                "email_id"          : openEmail ? openEmail._id             : "",
                "email_description" : openEmail ? openEmail.log_description : "",
                "messages"          : JSON.stringify(messages),
            }
        );
        webSocket.current = new WebSocket("ws" + backend_address.slice(4) + "/llmapi_streaming");

        webSocket.current.onopen = (event) => {
            // Custom replacer function to handle circular references and other non-serializable properties
            const replacer = (key, value) => {
                // Omit properties that might cause circular references
                if (key === 'target' || key === 'srcElement' || key === 'currentTarget') {
                    return undefined;
                }
                return value;
            };

            // Stringify the event object using the custom replacer
            const eventString = JSON.stringify(event, replacer, 4); // Pretty-print with 4-space indentation

            logEvent(participant._id, "frontend___debuggingPage_llmChat_websocket_onOpenEvent", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify(messages),
                    "event"             : eventString,
                }
            );
        }
        webSocket.current.onclose = (event) => {
            // Custom replacer function to handle circular references and other non-serializable properties
            const replacer = (key, value) => {
                // Omit properties that might cause circular references
                if (key === 'target' || key === 'srcElement' || key === 'currentTarget') {
                    return undefined;
                }
                return value;
            };

            // Stringify the event object using the custom replacer
            const eventString = JSON.stringify(event, replacer, 4); // Pretty-print with 4-space indentation

            logEvent(participant._id, "frontend___debuggingPage_llmChat_websocket_onCloseEvent", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify(messages),
                    "event"             : eventString,
                }
            );
        }
    }

    useEffect(() => {
        openWebSocket();

        return () => {
            logEvent(participant._id, "frontend___debuggingPage_llmChat_websocket_closing", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify(messages),
                }
            );
            webSocket.current.close();
        }
    }, []);

    useEffect(() => {
        webSocket.current.onmessage = (event) => {
            const partialServerMessage = JSON.parse(event.data);
            setMessages(prevMessages => {
                if (partialServerMessage.isEnd === "true") {
                    logEvent(participant._id, "frontend___debuggingPage_llmChat_receivedServerMessage_finalPart", {
                            "email_id"          : openEmail ? openEmail._id             : "",
                            "email_description" : openEmail ? openEmail.log_description : "",
                            "messages"          : JSON.stringify(messages),
                        }
                    );
                    setIsServerWriting(isServerWriting => false);
                    return [...prevMessages]; // No change needed
                } else {
                    // // Far too verbose
                    // // Removed
                    // logEvent(participant._id, "frontend___debuggingPage_llmChat_receivedServerMessage_nonFinalPart", {
                    //         "email_id"          : openEmail ? openEmail._id             : "",
                    //         "email_description" : openEmail ? openEmail.log_description : "",
                    //         "response"          : partialServerMessage.message,
                    //     }
                    // );
                    
                    const lastMessage = prevMessages[prevMessages.length - 1];
                    if (lastMessage && lastMessage.role === "assistant") {
                        // Append to last message
                        const updatedMessages = prevMessages.slice(0, -1); // Remove last message
                        lastMessage.content += partialServerMessage.message;
                        return [...updatedMessages, lastMessage];
                    } else {
                        // Add new server message
                        const newServerMessage = {
                            role: "assistant",
                            content: partialServerMessage.message
                        };
                        return [...prevMessages, newServerMessage];
                    }
                }
            });
        };
    }, []);

    useEffect(() => {
        if(bottomOfChatRef.current) {
            bottomOfChatRef.current.scrollIntoView({ behavior: 'instant'});
        }
    }, [messages]);

    const handleSendMessage = () => {
        if (webSocket.current.readyState === WebSocket.OPEN && isServerWriting === false && input.trim() !== '') {
            logEvent(participant._id, "frontend___debuggingPage_llmChat_sendingMessageToServer_start", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify(messages),
                }
            );

            // The user can only send a single message
            // Afterwards, the server must respond
            setIsServerWriting(isServerWriting => true);

            // New message from the client
            const newUserMessage = {
                role: "user",
                content: input
            };

            // Add the new message to the list of messages
            setMessages(messages => [...messages, newUserMessage]);

            // Clean the input field
            setInput('');
            
            let messageForServer = JSON.stringify({
                "participant_id"    : participant._id,
                "email_id"          : openEmail ? openEmail._id : "",
                "email_description" : openEmail ? openEmail.log_description : "",
                "messages"          : JSON.stringify([...messages, newUserMessage])
            });

            // Log
            logEvent(participant._id, "frontend___debuggingPage_llmChat_sendingMessageToServer_start_content", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify([...messages, newUserMessage]),
                    "messages_newUserMessage" : newUserMessage["content"],
                    "messages_forServerAll"   : messageForServer,
                }
            );

            // Send to server
            webSocket.current.send(messageForServer);

            // Add a dummy empty message from the assistant
            setMessages(messages => [...messages, {
                role: 'assistant',
                content: ''
            }]);
        }
    };

    const handleKeyEnter = (event) => {
        if(event.keyCode === KEY_CODE__ENTER){
            logEvent(participant._id, "frontend___debuggingPage_llmChat_pressedEnterKey", {
                    "email_id"          : openEmail ? openEmail._id             : "",
                    "email_description" : openEmail ? openEmail.log_description : "",
                    "messages"          : JSON.stringify(messages),
                }
            );
            handleSendMessage();
        }
    }

    const renderLLMNewLines = (content) => {
        const lines = content.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index !== lines.length - 1 && <br />}
            </React.Fragment>
        ));
    };

    return (
        <Container>
            <Paper 
                elevation={15}
                style={{ padding: '10px', marginTop: '10px' }}
            >
                <div
                    style={
                        {
                            height: "72vh",
                            maxHeight: "72vh",
                            overflowY: 'scroll',
                            marginBottom: '0px'
                        }
                    }
                >
                    {
                        messages.map(
                            (message, index) => {
                                if (message.role === 'system') {
                                    return null;
                                }
                                
                                return (
                                    <div
                                        key={index}
                                        style={
                                            {
                                                display: 'flex',
                                                justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
                                                textAlign: message.role === 'user' ? 'right' : 'left',
                                                marginRight: '5px'
                                            }
                                        }
                                    >
                                        <div>
                                            {message.role === 'assistant' && (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Avatar
                                                        src={llmImageIconPath}
                                                        style={{ marginRight: '3px' }}
                                                    />
                                                    <div
                                                        style={{
                                                            width: 0,
                                                            height: 0,
                                                            borderRight:
                                                                '15px solid ' + theme.llmChatPalette.bot,
                                                            borderTop: '10px solid transparent',
                                                            borderBottom: '10px solid transparent',
                                                        }}
                                                    ></div>
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            style={
                                                {
                                                    maxWidth: '70%',
                                                    wordWrap: 'break-word',
                                                    marginBottom: '5px',
                                                    backgroundColor:
                                                        message.role === 'user' ? '#cce5ff' : theme.llmChatPalette.bot,
                                                    padding: '8px',
                                                    borderRadius: '5px'
                                                }
                                            }
                                        >
                                            {message.content === '' ? (
                                                <CircularProgress
                                                    size={24}
                                                />
                                            ) : (
                                                <Typography
                                                    variant="body1"                                        
                                                >
                                                    {renderLLMNewLines(message.content)}
                                                </Typography>
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                    <ListItem ref={bottomOfChatRef}></ListItem>
                </div>
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                        xs={11}
                    >
                        <TextField
                            label="Type a message"
                            variant="outlined"
                            fullWidth
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            style={{
                                marginTop: '3px',
                                marginBottom: '10px',
                            }}
                            onKeyDown={handleKeyEnter}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={1}
                    >
                        <div 
                            style={{ 
                                height: '100%',
                                paddingLeft: '10px',
                                paddingBottom: '10px'
                            }}
                        >
                            {isServerWriting ? (
                                <LinearProgress style={{ height: '100%' }} />
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSendMessage}
                                    style={{width: '100%', height: '100%', fontWeight: 'bold'}}
                                >
                                    Send
                                </Button>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}
