import { Typography, Container, List, ListItem, Paper, Box, Button, Grid } from '@mui/material'
import { Subtitle, Title } from '../components/base/typography';
import { useLocalStorage } from '@uidotdev/usehooks';
import { BACKEND_ADDRESS } from '../App';

import Consent from '../components/framework/Consent';
import { baseExperimentStatus, Pages, ExperimentModes, userGroups } from '../Types';

export default function ConsentShort () {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);
    const debuggingButtonsBackgroundColors = [
        "black",
        "red",   "red",   "red",
        "green", "green", "green",
        "blue",  "blue",  "blue"
    ];

    const createUserOnTheBackend = (isRoundRobin, userGroup) => {

        fetch(BACKEND_ADDRESS + "/users", {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                "id":               participant.platformId ? participant.platformId : "NOID",
                'is_round_robin':   isRoundRobin,
                'group':            userGroup
            })
        })
            .then(async (res) => {
                let res_json = await res.json();
                setParticipant(res_json);
                setExperiment({ ...experiment, page: Pages.PRE_QUESTIONNAIRE })
            })
            .catch((err) => console.log(err));

    };

    const getRandomUserGroup = () => {
        const randomIndex = Math.floor(Math.random() * userGroups.length);
        const newUserGroup = userGroups[randomIndex];
        return newUserGroup;
    };

    const handleClickRandom = () => {
        createUserOnTheBackend("False", getRandomUserGroup());
    };

    const handleClickRoundRobin = () => {
        // Second argument is ignored if the first one is "True"
        createUserOnTheBackend("True", "something_random");
    };

    const handleClickDebugging = (userGroup) => {
        createUserOnTheBackend("False", userGroup);
    };

    return (
        <Container>

            <Title>
                Large Language Models for Phishing Training and Education
            </Title>

            {
                experiment.experimentMode === ExperimentModes.DEBUGGING &&
                <Title
                    style={{
                        color:'red',
                        fontSize:'2rem',
                        border: '5px solid red',
                        padding: '7px'
                    }}
                >
                    Frontend is in Debugging Mode
                    <br/>
                    <br/>
                    Change './Types.js' -&gt; baseExperimentStatus -&gt; "experimentMode"
                </Title>
            }

            <Typography pb={2}>
                We are a team of researchers from <a href='https://ethz.ch'>ETH Zurich</a>, a public research
                university in Switzerland, investigating how people interact with email clients and online safety.
            </Typography>

            <Typography pb={2}>
                We are designing novel ways to help users detect fraudulent emails using Large Language Models - that
                you might know as “chatbots” such as the popular ChatGPT and Google Gemini. With this study, we want
                to analyze their usability: we would like to ask you if you are willing to participate in our project.
                Your participation is voluntary.
            </Typography>

            <Typography pb={2}>
                You will be asked to imagine you are an employee working at a fictitious company and have a fixed
                amount of time to handle as many emails as possible. You will be guided through your tasks with a
                tutorial.
            </Typography>

            <Typography pb={2}>   
                To manage the emails, you might be helped by our newly developed tools; some of them might feature a
                chatbot. You do not have to interact with the chatbot if you do not want to, and this will not change
                the evaluation of your performance in the study.
            </Typography>

            <Typography pb={2}>   
                We will record your answers to the questionnaires, and your performance during the study: how many
                emails were handled, how much time every email took you, and how much time you spent on the different
                tutorial and study pages. Should you decide to interact with the chatbots, your messages and the bot
                replies will also be recorded.
            </Typography>

            <Typography pb={2}>
                No personally identifiable information about you is recorded. No data from this study will be
                published, except for aggregated statistics. Quotes or excerpts from your interaction with the LLM may
                be reported in scientific publications or other materials, given that they do not contain any personal
                data or allow for your identification.
            </Typography>

            <Typography pb={2}>
                You can read the full consent form at <a href='./consent-full' target='_blank'>this link</a>.
            </Typography>

            <Typography>
                You can contact the research team by writing to Daniele Lain, ETH Zurich&nbsp;
                (<a href='mailto:daniele.lain@inf.ethz.ch'>daniele.lain@inf.ethz.ch</a>).
            </Typography>

            <Typography>
                The Data Protection Officer of ETH Zurich is Tomislav Mitar&nbsp;
                (<a href='mailto:tomislav.mitar@sl.ethz.ch'>tomislav.mitar@sl.ethz.ch</a>).
            </Typography>

            <Typography pb={2}>
                This project was approved by the ETH Zurich ethics committee as EK-2024-N-104.
            </Typography>

            <Typography>
                The secretariat of the ETH Zurich Ethics Committee is available to help you with complaints in
                connection with your participation.
            </Typography>

            <Typography pb={2}>
                Contact:&nbsp;
                <a href='mailto:ethics@sl.ethz.ch'>ethics@sl.ethz.ch</a>&nbsp;
                or&nbsp;
                <a href='tel:0041446328572'>+41 44 632 85 72</a>.
            </Typography>

            <Paper
                elevation={3}
                sx={{
                    padding: "1em 4em",
                    backgroundColor: "#EEE"
                }}
            >
                <Subtitle>
                    Consent Form
                </Subtitle>

                <Typography paddingBottom={2}>
                    I, the participant, confirm by checking the box and submitting the form that:
                </Typography>

                <List dense>
                    <ListItem key={1}>
                        • I have read and understood the&nbsp; <a href='./consent-full'>full study information</a>.
                    </ListItem>

                    <ListItem key={2}>
                        • I comply with the inclusion and exclusion criteria for participation described above.
                        I am aware of the requirements and restrictions to be observed during the study.
                    </ListItem>

                    <ListItem key={3}>
                        • I have had enough time to decide about my participation.
                    </ListItem>

                    <ListItem key={4}>
                        • I participate in this study voluntarily and consent that my data be used as
                        described above.
                    </ListItem>

                    <ListItem key={5}>
                        • I understand that I can stop participating at any moment.
                    </ListItem>
                </List>

                <Consent consentHandler={handleClickRoundRobin} />

                {
                    experiment.experimentMode === ExperimentModes.DEBUGGING &&
                    <center>
                        <Box py={2}></Box>
                        <Grid
                            container
                            spacing={2}
                            style={{
                                border: '5px solid red',
                                paddingRight: '1em',
                                paddingBottom: '1em'
                            }}
                        >
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    style={{ backgroundColor: debuggingButtonsBackgroundColors[0] }}
                                    onClick={() => handleClickDebugging(userGroups[0])}
                                >
                                    {userGroups[0]}
                                </Button>
                            </Grid>
                            {[...Array(9)].map((_, index) => (
                                <Grid item xs={4} key={index}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        style={{ backgroundColor: debuggingButtonsBackgroundColors[index+1] }}
                                        onClick={() => handleClickDebugging(userGroups[index+1])}
                                    >
                                        {userGroups[index+1]}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </center>
                }
                
            </Paper>
            
            <Box py={2}></Box>

        </Container>
    );
}