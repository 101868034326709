import React, { useEffect, useState } from 'react';
import { Typography, Container, Paper, Button, Grid, ButtonBase, Stack } from '@mui/material'
import { ArrowCircleLeft } from '@mui/icons-material';
import { theme } from '../../../theme';
import { logEvent } from '../../../networking';
import { useLocalStorage } from '@uidotdev/usehooks';
import LoggerDialog from '../../dialogs/LoggerDialog';
import { getActiveElement } from '../../../Types';


export default function MCEmailClues({participant, openEmail, clickHandlerShowBlockingWarnings, ...props}) {
    const [cluesSteps, setCluesSteps] = useState([]);
    const [clueDetailsDialogOpen, setClueDetailsDialogOpen] = useState(false);
    const [clueDetailsContent, setClueDetailsContent] = useState(false);

    useEffect(() => {
        setCluesSteps(
            openEmail != null ? eval(`(${openEmail.clues_steps_str})`) : []
        );
    }, [openEmail]);

    const cluesDetailsHandler = (clueIndex, clueText, clueDetails) => {
        logEvent(participant._id, "frontend___mailClient_passiveWarningsClueWidget_clueDetails_activated", {
                "email_id"          : openEmail ? openEmail._id : "",
                "email_description" : openEmail ? openEmail.log_description : "",
                "clue_index"        : clueIndex,
                "clue_text"         : clueText,
                "clue_details"      : clueDetails,
            }
        );
        setClueDetailsContent(clueDetails);
        setClueDetailsDialogOpen(true);
    };

    return (
        <Container 
            maxWidth="sm"
        >
            <Paper 
                elevation={10}
                style={{
                    marginTop: '10px',
                    height: '100%',
                    maxHeight: '100%',
                    border: '5px solid '+theme.cluesPalette.primary,
                    textAlign: 'center',
                }}
            >
                <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                        backgroundColor: theme.cluesPalette.primary,
                        padding: '5px',
                        paddingBottom: '8px',
                        color: 'white',
                        marginBottom: '0px',
                    }}
                >
                    Suspicious Email Indicators
                </Typography>

                <Grid
                    container
                    direction="row"
                    style={{
                        padding: '5px'
                    }}
                >
                    <Grid
                        item
                        xs={clickHandlerShowBlockingWarnings ? 2.5 : 0}
                        style={{
                            paddingRight: '5px',
                        }}
                    >
                        {
                            clickHandlerShowBlockingWarnings &&
                            <Grid
                                style={{
                                    height: '100%',
                                }}
                            >
                                <ButtonBase
                                    variant="contained"
                                    onClick={clickHandlerShowBlockingWarnings}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        backgroundColor: theme.cluesPalette.primary,
                                        color: 'white',
                                    }}
                                >
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <ArrowCircleLeft
                                            style={{
                                                fontSize: '60px'
                                            }}
                                        />
                                        <Typography
                                            style={{
                                                fontWeight: 'bold',
                                                marginTop: '-3px'
                                            }}
                                        >
                                            Show
                                        </Typography>
                                    </Stack>
                                </ButtonBase>
                            </Grid>
                        }
                    </Grid>

                    <Grid
                        item
                        xs={clickHandlerShowBlockingWarnings ? 9.5 : 12}
                    >
                        <div
                            style={
                                {
                                    height: '100px',
                                    maxHeight: '100px',
                                    width: '100%',
                                    maxWidth: '100%',
                                    overflowY: 'auto',
                                    marginBottom: '5px',
                                }
                            }
                        >
                            {
                                cluesSteps.map(
                                    (cluesStep, index) => (
                                        <div
                                            key={index}
                                            style={
                                                {
                                                    textAlign: 'left',
                                                    marginLeft: '2px',
                                                    marginRight: '5px',
                                                    marginBottom: '3px',
                                                    backgroundColor: theme.cluesPalette.secondary,
                                                    padding: '3px',
                                                    paddingLeft: '10px',
                                                    paddingRight: '5px',
                                                    borderRadius: '5px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }
                                            }
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    paddingRight: '10px',
                                                }}
                                            >
                                                {cluesStep.text}
                                            </Typography>
                                            {
                                                getActiveElement(participant.group, "Right-hand side details") &&
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => cluesDetailsHandler(index, cluesStep.text, cluesStep.details)}
                                                    style={{
                                                        color: theme.cluesPalette.primary,
                                                        backgroundColor: 'white',
                                                        borderColor: theme.cluesPalette.primary,
                                                        fontWeight: 'bold',
                                                        padding: '0px',
                                                    }}
                                                >
                                                    Details
                                                </Button>
                                            }
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </Grid>
                </Grid>
            </Paper>

            <LoggerDialog
                pageName="mailClient"
                open={clueDetailsDialogOpen}
                onClose={() => setClueDetailsDialogOpen(false)}
                title="Details"
                description={clueDetailsContent}
                eventName="clue_details_dialog"
                cancelText="Ok"
            />
        </Container>
    );
}
