import React, { useEffect, useState } from 'react';
import { Typography, Container, Paper, Button, ListItem } from '@mui/material'
import { theme } from '../../../theme';
import { logEvent } from '../../../networking';
import LoggerDialog from '../../dialogs/LoggerDialog';
import { getActiveElement } from '../../../Types';
import { styled } from '@mui/material/styles';
import { TP_Title, TP_Paragraph } from '../../base/typography';


export default function TPEmailClues({participant, openEmail, callback, ...props}) {
    const [cluesSteps, setCluesSteps] = useState([]);
    const [clueDetailsDialogOpen, setClueDetailsDialogOpen] = useState(false);
    const [clueDetailsContent, setClueDetailsContent] = useState(false);

    useEffect(() => {
        setCluesSteps(
            openEmail != null ? eval(`(${openEmail.clues_steps_str})`) : []
        );
    }, [openEmail]);

    const cluesDetailsHandler = (clueIndex, clueText, clueDetails) => {
        logEvent(participant._id, "frontend___trainingPage_passiveWarningsClueWidget_clueDetails_activated", {
                "email_id"          : openEmail ? openEmail._id : "",
                "email_description" : openEmail ? openEmail.log_description : "",
                "clue_index"        : clueIndex,
                "clue_text"         : clueText,
                "clue_details"      : clueDetails,
            }
        );
        setClueDetailsContent(clueDetails);
        setClueDetailsDialogOpen(true);
    };

    return (
        <Container>
            
            <TP_Paragraph
                style={{
                    paddingTop: "15px"
                }}
            >
                Unfortunately, you've fallen for a simulated phishing email. But don't worry, this is a learning
                opportunity! Phishing is a common online scam where criminals impersonate legitimate organizations
                to steal sensitive information. Let's learn how to spot them.
            </TP_Paragraph>
            
            <TP_Title>
                How Could I Have Spotted <span
                    style={{
                        color: 'red',
                        textDecoration: 'underline',
                        fontWeight: 'bold'
                }}>THIS</span> Phishing Email?
            </TP_Title>
            
            <ul>
                {
                    cluesSteps.map((cluesStep, index) => (
                        <li
                            key={index}
                        >
                            <div
                                style={
                                    {
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }
                                }
                            >

                                <b>
                                    {cluesStep.trainingPageText}
                                </b>

                                <div
                                    style={{
                                        width: '80px',
                                        minWidth: '80px',
                                        maxWidth: '80px',
                                        marginLeft: '5px',
                                        marginBottom: '2px',
                                        height: '30px',
                                    }}
                                >
                                    {
                                        getActiveElement(participant.group, "Training page with details buttons") &&
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => cluesDetailsHandler(index, cluesStep.text, cluesStep.details)}
                                            style={{
                                                color: theme.palette.primary.main,
                                                backgroundColor: 'white',
                                                borderRadius: '5px',
                                                borderColor: theme.palette.primary.main,
                                                fontWeight: 'bold',
                                                width: '100%', // Make the button width 100% of its parent container
                                                height: '100%', // Make the button height 100% of its parent container
                                            }}
                                        >
                                            Details
                                        </Button>
                                    }
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
            
            <TP_Title>
                Why is Phishing Dangerous?
            </TP_Title>
            
            <TP_Paragraph>
                Phishing is a type of online scam where criminals send an email that appears to be from a legitimate
                sender, asking you to provide sensitive information. This is usually done by including a link that will
                appear to take you to a website to fill in your information - but the website is a clever fake and the
                information you provide goes straight to the crooks behind the scam.
            </TP_Paragraph>

            <TP_Title>
                Conclusion
            </TP_Title>
            
            <TP_Paragraph>
                Remember, when in doubt, it's better to be safe than sorry. If you're unsure whether an email is a
                scam, report the email directly. We hope this training page helps you in identifying phishing
                emails in the future. Stay safe online!
            </TP_Paragraph>

            <Button
                variant="contained"
                color="primary"
                onClick={callback}
                style={{
                    width: '100%',
                    height: '100%',
                    fontWeight: 'bold',
                    fontSize: '2rem',
                    marginTop: '2rem',
                }}
            >
                GO BACK
            </Button>

            <LoggerDialog
                pageName="trainingPage"
                open={clueDetailsDialogOpen}
                onClose={() => setClueDetailsDialogOpen(false)}
                title="Details"
                description={clueDetailsContent}
                eventName="clue_details_dialog"
                cancelText="Ok"
            />
        </Container>
    );
}
