import { AppBar, Grid, Container, Toolbar, ButtonBase } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Title } from '../../base/typography';
import { EmailTypes } from '../../../Types';


export default function TPHeader({ callback = null, ...props }) {
    return (
        <div style={{ height: '10vh' }}>
            <Grid container style={{ height: '100%', alignItems: 'center' }}>
                <AppBar position="sticky" style={{ height: '100%' }}>
                    <Container maxWidth="xl" style={{ height: '100%' }}>
                        <Toolbar disableGutters style={{ height: '100%' }}>
                            <ButtonBase onClick={callback}>
                                <ArrowBack sx={{ fontSize: '4rem' }} />
                            </ButtonBase>

                            <Title 
                                gutterBottom
                                sx={{
                                    flexGrow: 1,
                                    textAlign: 'center',
                                    fontSize: '2.5rem'
                                }}
                            >
                                You've Been Phished! Let's Learn How to Spot the Signs
                            </Title>
                        </Toolbar>
                    </Container>
                </AppBar>
            </Grid>
        </div>
    );
};
