import CssBaseline from '@mui/material/CssBaseline';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';

import { baseExperimentStatus, Pages } from './Types';

import { UAParser } from 'ua-parser-js';

// React Hooks
import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useMemo } from 'react';

// Pages
import ConsentShort from "./pages/2__ConsentShort";
import ConsentFull from './pages/2_2__ConsentFull';
import PreQ from "./pages/3__PreQ";
import Background from "./pages/4__Background";
import MailClient from "./pages/5__MailClient";
import TrainingPage from "./pages/5_2__TrainingPage";
import TrainingPagePost from "./pages/5_3__TrainingPagePost";
import DebuggingPage from "./pages/5_4__DebuggingPage";
import Debriefing from "./pages/6__Debriefing";
import PostQ from "./pages/7__PostQ";
import Reward from "./pages/8__Reward";
import Mobile from './pages/Mobile';

export const BACKEND_ADDRESS = process.env.REACT_APP_BACKEND_ADDRESS || process.env.REACT_APP_BACKEND_LOCAL


export default function App() {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);

    let isMobile = useMemo(() => {
        const parser = new UAParser();
        return parser.getDevice().type;
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [experiment.page]);

    // get the URL paramenter PLATFORM_ID
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const platformId = urlParams.get('PLATFORM_ID');
        if (platformId) {
            setParticipant({ ...participant, platformId: platformId });
        }
        else {
            setParticipant({ ...participant, platformId: null });
        }
    }, []);


    if (isMobile) {
        return (
            <CssBaseline>
                <ThemeProvider theme={theme}>
                    <Mobile />
                </ThemeProvider>
            </CssBaseline>
        );
    }
    else {
        return (
            <CssBaseline>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={
                                <>
                                    {
                                        !isMobile &&
                                        <>
                                            { experiment.page === Pages.CONSENT_SHORT && <ConsentShort /> }
                                            { experiment.page === Pages.PRE_QUESTIONNAIRE && <PreQ /> }
                                            { experiment.page === Pages.BACKGROUND && <Background /> }
                                            { experiment.page === Pages.MAILBOX && <MailClient /> }
                                            { experiment.page === Pages.TRAINING_PAGE && <TrainingPage /> }
                                            { experiment.page === Pages.TRAINING_PAGE_POST && <TrainingPagePost /> }
                                            { experiment.page === Pages.DEBUGGING_PAGE && <DebuggingPage /> }
                                            { experiment.page === Pages.DEBRIEFING && <Debriefing /> }
                                            { experiment.page === Pages.POST_QUESTIONNAIRE && <PostQ /> }
                                            { experiment.page === Pages.REWARD && <Reward /> }
                                        </>
                                    }
                                    {
                                        isMobile &&
                                        <Mobile />
                                    }
                                </>
                            } />
                            <Route path="consent-full" element={<ConsentFull />} />
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </CssBaseline>
        );
    }
}