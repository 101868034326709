import {Button, Box, Typography, Container} from '@mui/material'
import { useLocalStorage } from '@uidotdev/usehooks';
import { Subtitle, Title, Preformatted } from '../components/base/typography';
import { ExperimentProgress, Pages, baseExperimentStatus } from '../Types';

export default function Debriefing() {

    const [employee, setEmployee] = useLocalStorage("employee", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);

    const handleClickConfirm = () => {
        setExperiment({ ...experiment, page: Pages.POST_QUESTIONNAIRE })
    };

    return (
        <Container>

            <Title>
                Thank you! The experiment is over
            </Title>

            <Subtitle>
                {experiment.status === ExperimentProgress.OVER && "You completed the experiment."}
                {experiment.status === ExperimentProgress.LEFT_EARLY && "You abandoned the experiment early."}
                {experiment.status === ExperimentProgress.TIMEOUT && "You reached the end of the allocated time."}
            </Subtitle>

            <Typography paddingBottom={2}>
                Thanks for taking part in our usability study for LLM phishing detection!
            </Typography>

            <Typography paddingBottom={2}>
                Some emails that {employee.name} {employee.surname} received in their inbox were <b>phishing emails</b>:
                scams where criminals try to impersonate some other entities and try to steal information such as
                passwords and personal data.
            </Typography>

            <Typography paddingBottom={6}>
                Our study measured the performance of phishing email detection methods that try to protect users.
                By testing one of these, you are helping us develop secure computer interfaces and securing all
                Internet users.
            </Typography>

            <Typography paddingBottom={2}>
                <b>
                    We'll ask you a few more question on a last, short questionnaire in the next page: you will then
                    receive your participation code.
                </b>
            </Typography>

            <Box py={2}></Box>
            <Button variant="contained" className="Button" onClick={(event) => handleClickConfirm(event)}>
                Continue
            </Button>

            <Box py={2}></Box>

        </Container>
    );
}