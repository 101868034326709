import { AppBar, Grid, Container, Toolbar, ButtonBase } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Title } from '../../base/typography';


export default function DPHeader({ callback = null, ...props }) {
    return (
        <div style={{ height: '10vh' }}>
            <Grid container style={{ height: '100%', alignItems: 'center' }}>
                <AppBar position="sticky" style={{ height: '100%' }}>
                    <Container maxWidth="xl" style={{ height: '100%' }}>
                        <Toolbar disableGutters style={{ height: '100%' }}>
                            <ButtonBase onClick={callback}>
                                <ArrowBack sx={{ fontSize: '4rem' }} />
                            </ButtonBase>

                            <Title 
                                gutterBottom
                                sx={{ flexGrow: 1, textAlign: 'center' }}
                            >
                                Debugging Page
                            </Title>
                        </Toolbar>
                    </Container>
                </AppBar>
            </Grid>
        </div>
    );
};
