import MailboxHeader from '../../mailbox/MailboxHeader';


export default function MCHeader({ name = null, logo = null, ...props }) {
    return (
        <MailboxHeader
            name={name}
            logo={logo}
            {...props}
        />
    );
};
